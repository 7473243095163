import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import Range from "../../../../components/shared/InputElements/Range";
import { useIntl } from 'react-intl';


const SlideCostPublicCharging = ({
    id = "cost-public-charging-range",
    ...rest
}) => {
    const intl = useIntl()

    const optionNames = {
        0: intl.formatMessage({ id: "ev.publicCharging.free", defaultMessage: "Free" }),
        0.1: `${intl.formatMessage({ id: "ev.publicCharging.low", defaultMessage: "Low" })}: $0.10/kWh`,
        0.2: "$0.20/kWh",
        0.3: `${intl.formatMessage({ id: "ev.publicCharging.medium", defaultMessage: "Medium" })}: $0.30/kWh`,
        0.4: "$0.40/kWh",
        0.5: "$0.50/kWh",
        0.6: `${intl.formatMessage({ id: "ev.publicCharging.high", defaultMessage: "High" })}: $0.60/kWh`
    };

    const description = val => {
        return `${optionNames[val]}`;
    };
    const label = intl.formatMessage ? intl.formatMessage({ id: "ev.costOfPublicCharing", defaultMessage: "Est. cost of public charging" }) : "Est. cost of public charging"
    const userPrefs = useContext(UserPrefsContext);

    return (
        <Range
            id={id}
            value={userPrefs.get("costPublicCharging")}
            label={label}
            rangeMin={0}
            rangeMax={0.6}
            rangeStep={0.1}
            description={description}
            handler={e => userPrefs.set({ costPublicCharging: e.target.value })}
            {...rest}
        />
    );
};

export default SlideCostPublicCharging;

SlideCostPublicCharging.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    description: PropTypes.func
};
