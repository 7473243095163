import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import "./Homepage.scss";
import ShuffleFlatArray from "../../../utils/Helpers/ShuffleFlatArray";
import VehiclesCarousel from "../VehicleCarousel/VehicleCarousel"
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import LinkCard from "../LinkCard/LinkCard"
import mobileBanner from "../../assets/images/mobile-banner.png"
import { FormattedMessage } from 'react-intl';

const Homepage = ({
  homePageBannerImage,
  electricVehicles,
  incentives,
  userLocation,
  ip,
  uuid,
  language, 
  changeLanguage, 
  defaultLanguage,
  ...props
}) => {

  const [vehicles, setVehicles] = useState([]);

  useEffect(() => {
    setElectricVehicles();
  });

  useEffect(() => {
    defaultLanguage && changeLanguage(defaultLanguage)
    }, [defaultLanguage, changeLanguage])

  const setElectricVehicles = () => {
    if (electricVehicles) setVehicles(ShuffleFlatArray(electricVehicles));
  }

  const renderDetails = electricVehicles && incentives ?
    (
      <VehiclesCarousel
        electricVehicles={vehicles}
      />
    ) : (
      <section className="container">
        <LoadingSpinner />
      </section>
    );

  return (
    <main>
      <section className="container-fluid homepage p-0">
        <div className="container">
          <h1><FormattedMessage
            id="homepage.title"
            defaultMessage="Your EV cost calculator guide"
            description="Your EV cost calculator guide"
          /></h1>
          <div id="link-card-buttons">
            <LinkCard {...props} type="BROWSE_VEHICLES" />
            <LinkCard {...props} type="BROWSE_USED_VEHICLES" />
            <LinkCard {...props} type="COMPARE_VEHICLES" />
            <LinkCard {...props} type="SEE_INCENTIVES" />
          </div>

          {/* Desktop Display */}
          <div className="row d-none d-lg-flex subheader-vc-row">
            <div className="col-4">
              <h1><FormattedMessage
                id="homepage.subTitle"
                defaultMessage="Jump straight to popular EVs available today"
                description="Jump straight to popular EVs available today"
              /></h1>
              {/* <h3><FormattedMessage
                id="homepage.compare"
                defaultMessage="Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it can be cheaper to own and maintain than a 100% gasoline-powered car."
                description="Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it can be cheaper to own and maintain than a 100% gasoline-powered car."
              /></h3> */}

            </div>
            <div className="col-8">
              {renderDetails}
            </div>
          </div>

          {/* Mobile Display */}
          <div className="row d-block d-lg-none bottom-container">
            <div className="col-12 no-padding">
              <h1><FormattedMessage
                id="homepage.subTitle"
                defaultMessage="Jump straight to popular EVs available today"
                description="Jump straight to popular EVs available today"
              /></h1>
              {/* <h3><FormattedMessage
                id="homepage.compare"
                defaultMessage="Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it can be cheaper to own and maintain than a 100% gasoline-powered car."
                description="Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it can be cheaper to own and maintain than a 100% gasoline-powered car."
              /></h3> */}
            </div>
            <div className="col-12 no-padding">
              {renderDetails}
            </div>
          </div>

        </div>
        <div className="d-flex d-lg-none">
          <img className="mobile-homepage-banner" src={mobileBanner} alt="vehicle on road"/>
        </div>
      </section>
    </main>
  );
};

export default Homepage;

Homepage.propTypes = {
  homePageBannerImage: PropTypes.string,
  electricVehicles: PropTypes.array,
  incentives: PropTypes.array,
  ip: PropTypes.string,
  uuid: PropTypes.string,
  language: PropTypes.string,
  defaultLanguage: PropTypes.string,
};
