import React, { useState } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import './FeedbackModal.scss';
import { FormattedMessage } from "react-intl";
const crypto = require('crypto');

const FeedbackModal = (language) => {
  
  const [isOpen, setIsOpen] = useState(false);
  // const [isSaving, setIsSaving] = useState(false);
  const [feedback, setFeedback] = useState({});

  const toggle = () => setIsOpen(!isOpen);

  const onChangeFeedback = (e) => {
    const { name, value } = e.target;
    setFeedback((prevFeedback) => ({ ...prevFeedback, [name]: value }));
  };

  // const isSubmittable = () => {
  //   if (isSaving) return false;
  //   return feedback.goal.length || feedback.buyEV !== null || feedback.suggestions.length;
  // };

  const onSubmit = (e) => {
    e.preventDefault();
    // if (!isSubmittable()) return;
    // setIsSaving(true);
    setIsOpen(false);
    recordFeedback();
    setFeedback({});
    // console.log('was submit', feedback);
  };
  
  const recordFeedback = () => {
    let url = new URL(
      process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_FEEDBACK_PROD_BACKEND_HOST : process.env.REACT_APP_FEEDBACK_DEV_BACKEND_HOST
    );

    const payload = Buffer.from(JSON.stringify(feedback)).toString('base64');
    const feedback_info = {
      service: process.env.REACT_APP_ENVIRONMENT === "production" ? process.env.REACT_APP_FEEDBACK_PROD_SERVICE_ID : process.env.REACT_APP_FEEDBACK_DEV_SERVICE_ID,
      payload: [payload],
      checksum: "-Sha1-" + crypto.createHash("sha1").update(payload, "utf-8").digest("hex")
    }

    window
      .fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(feedback_info)
      })
  }

  return (
    <div> 
      {language['language'] === "FR" ? 
        <button type="button" className="btn feedback-btn" id="feedback-fr" onClick={toggle}>
          <FormattedMessage
            id="feedback.title"
            defaultMessage="Feedback"
            description="Feedback Button Title"
          />
        </button> :
        <button type="button" className="btn feedback-btn" id="feedback" onClick={toggle}>
          <FormattedMessage
            id="feedback.title"
            defaultMessage="Feedback"
            description="Feedback Button Title"
          />
        </button>}
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <FormattedMessage
            id="feedback.title"
            defaultMessage="Feedback"
            description="Feedback Title"
          />
        </ModalHeader>
        <ModalBody>
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label htmlFor="feedback_goal">
                <FormattedMessage
                  id="feedback.goal"
                  defaultMessage="What is your goal of visiting?"
                  description="Goal Option Header"
                />
              </label>
              <textarea
                className="form-control no-arrow"
                name="goal"
                id="feedback_goal"
                rows="2"
                value={feedback.goal}
                onChange={onChangeFeedback}
              />
            </div>
            <div className="form-group">
              <label htmlFor="feedback_buyEV">
                <FormattedMessage
                  id="feedback.interest"
                  defaultMessage="Are you interested in purchasing an Electric Vehicle?"
                  description="Interest Option Header"
                />
              </label>
              <div className="form-check">
                <label className="form-check-label" htmlFor="BEV">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="buyEV"
                    id="BEV"
                    value="BEV"
                    checked={feedback.buyEV === 'BEV'}
                    onChange={onChangeFeedback}
                  />
                  <FormattedMessage
                    id="feedback.batteryElectric"
                    defaultMessage="Battery Electric"
                    description="Interest Option One"
                  />
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label" htmlFor="PHEV">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="buyEV"
                    id="PHEV"
                    value="PHEV"
                    checked={feedback.buyEV === 'PHEV'}
                    onChange={onChangeFeedback}
                  />
                  <FormattedMessage
                    id="feedback.pluginHybrid"
                    defaultMessage="Plug-In Hybrid"
                    description="Interest Option Two"
                  />
                </label>
              </div>
              <div className="form-check">
                <label className="form-check-label" htmlFor="noEV">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="buyEV"
                    id="noEV"
                    value="noEV"
                    checked={feedback.buyEV === 'noEV'}
                    onChange={onChangeFeedback}
                  />
                  <FormattedMessage
                    id="feedback.interest"
                    defaultMessage="Not Interested"
                    description="Interest Option Thre"
                  />
                </label>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="feedback_improve">
                <FormattedMessage
                  id="feedback.improve"
                  defaultMessage="How would you improve this tool?"
                  description="Improve Title"
                />
              </label>
              <textarea
                className="form-control no-arrow"
                rows="2"
                name="improve"
                id="feedback_improve"
                value={feedback.improve}
                onChange={onChangeFeedback}
              />
            </div>
            <div className="form-group">
              <label htmlFor="feedback_help">
                <FormattedMessage
                  id="feedback.help"
                  defaultMessage="What can Uber do to help you get an Electric Vehicle?"
                  description="Help Title"
                />
              </label>
              <textarea
                className="form-control no-arrow"
                rows="2"
                name="help"
                id="feedback_help"
                value={feedback.help}
                onChange={onChangeFeedback}
              />
            </div>

            <button type="submit" className="feedback-btn btn-pge">
              <FormattedMessage
                id="feedback.submit"
                defaultMessage="Submit"
                description="Submit Title"
              />
              {/* {isSaving ? 'Submitting...' : 'Submit'} Submit */}
            </button>
          </form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default FeedbackModal;
