import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"
import { Link } from "react-router-dom";
import "./UsedEVCard.scss";

import RenderItem from "../../../components/RenderItem/RenderItem"
import VehicleImage from "../../../components/VehicleImage/VehicleImage"
import { renderVehicleClassBadge, renderFuelTypeBadge } from '../../functions/vehicle/RenderBadges/RenderBadges'
// import calcMatchScore from "../../../functions/vehicle/MatchScore/calcMatchScore"
// import getPaymentDetails from "../../../functions/vehicle/getPaymentDetails"
import { FormatInKWithoutCurrency, FormatCarModelAndTrim } from "../../../utils/Helpers/Format"
import IconLocal from "../../assets/images/icons/icon-local.png";
import { useIntl } from 'react-intl';

const UsedEVCard = ({
    ev,
    hasEvDetails,
    hasIncentivesAndMatchScore,
    hasLinkToEv,
    hasLocallyAvailableHidden
}) => {
    const intl = useIntl()
    const userPrefs = useContext(UserPrefsContext);

    if (!ev) return null;

    let evImgs = ev.images ? ev.images.map((n) =>
        (n.url_thumbnail == null) ? [] :
            (n.url_full == null) ? [] :
                [n]) : [];

    //Flatten evImgs into a single array                  
    const evImgSrc = [].concat.apply([], evImgs);

    const imgSrc = ev.images ? (ev.images.length >= 0 ? evImgSrc[0] : "") : "";

    const renderLocalIcon = ev.locally_available ? (
        <span className="badge-locally-available">
            <img alt="Available Locally" src={IconLocal} />
        </span>
    ) : null;

    let altText = "Go to " + (ev.make + " " + ev.model + " " + ev.trim).toString().trim();


    const renderEVDetails = hasEvDetails ? (
        <div className="renderRowOfData">
            <p>
                <small>{intl.formatMessage({ id: "used.evCard.yearRange", defaultMessage: "Year range" })}</small>
                <span>{ev.yearRange}</span>
            </p>
            <p>
                {window.innerWidth > 600 ? <small>{intl.formatMessage({ id: "used.evCard.electricRangeAvg", defaultMessage: "Electric range average" })}</small> : <small>{intl.formatMessage({ id: "used.evCard.electricRangeAvg.short", defaultMessage: "Electric range avg." })}</small>}
                <span>{ev.electric_range}</span>
            </p>
        </div>
    ) : null;

    const renderCardBottom = hasIncentivesAndMatchScore ? (
        <div className="EVCardBottom">
            <div>
                <RenderItem
                    title={intl.formatMessage({ id: "vehicle.afterIncentives", defaultMessage: "After incentives" })}
                    value={ev.lowerBoundMsrp ? <><span className="monetarySymbol">{intl.formatMessage({ id: "currency", defaultMessage: "$" })}</span>{FormatInKWithoutCurrency(ev.lowerBoundMsrp - ev.usedIncentivesSavings)}-{FormatInKWithoutCurrency(ev.upperBoundMsrp - ev.usedIncentivesSavings)}</> : ev.msrp}
                />
            </div>
            <div>
                <RenderItem
                    title={intl.formatMessage({ id: "evCard.matchScore", defaultMessage: "Match score" })}
                    value={ev.matchScore}
                    matchScore
                />
            </div>
        </div>
    ) : (
        hasLinkToEv && (
            <div className="EVCardBottomWithLink">
                <Link
                    to={`${userPrefs.get("additionalParams")}/vehicles/${ev.handle}`}
                    className="btn btn-ae"
                    target="_blank"
                    style={{ outline: "none" }}
                >
                    {intl.formatMessage({ id: "evCard.seeDetails", defaultMessage: "SEE DETAILS" })}
                </Link>
            </div>
        )
    );

    return (
        <>
            <div className="EVCardTop">
                {!hasLocallyAvailableHidden && renderLocalIcon}
                <div className="badge-fuel-type">
                    {renderVehicleClassBadge(ev)}
                    {renderFuelTypeBadge(ev)}
                </div>
                <p className="h2" style={{ maxWidth: "70%" }}>
                    {ev.make}
                </p>
                <p className="modal-title mt-1">
                    {FormatCarModelAndTrim(ev)}
                </p>
                <div className="text-center">
                    <VehicleImage image={imgSrc} size="thumb" alt={altText} />
                </div>
                {renderEVDetails}
            </div>
            {renderCardBottom}
        </>
    );
};

export default UsedEVCard;

UsedEVCard.propTypes = {
    ev: PropTypes.object,
    hasEvDetails: PropTypes.bool,
    hasIncentivesAndMatchScore: PropTypes.bool,
    hasLinkToEv: PropTypes.bool,
    hasLocallyAvailableHidden: PropTypes.bool
};
