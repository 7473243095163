import React from "react";

import PropTypes from "prop-types";

import "./LinkCard.scss";

import BROWSE_VEHICLES from "../../assets/images/icons/browse-electric-vehicles.svg"
import BROWSE_USED_VEHICLES from "../../assets/images/icons/browse-electric-used-vehicles.svg"
import DISCOVER_INCENTIVES from "../../assets/images/icons/see-available-incentives.svg";
import COMPARE_VEHICLES from "../../assets/images/icons/compare-new-vehicles.svg";
import { Link } from "react-router-dom";
import {FormattedMessage} from 'react-intl';

const LinkCard = ({ type, ...props }) => {
  let src, link, title, alt;
  const path = props.location?.pathname.slice(-1) === "/" ? props.location.pathname.slice(0, -1) : props.location?.pathname

  switch (type) {
    case "BROWSE_VEHICLES":
      src = BROWSE_VEHICLES;
      alt = 'Browse Vehicles';
      link = `${path}/vehicles`;

      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.browseElectricVehicles"
            defaultMessage="Browse new {lineBreak} electric vehicles"
            description="HomePage Linkcard Browse Electric Vehicles"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "BROWSE_USED_VEHICLES":
      src = BROWSE_USED_VEHICLES;
      alt = 'Browse Used Vehicles';
      link = `${path}/used-vehicles`;

      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.browseUsedVehicles"
            defaultMessage="Browse used {lineBreak} electric vehicles"
            description="HomePage Linkcard Browse Used Electric Vehicles"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "COMPARE_VEHICLES":
      src = COMPARE_VEHICLES;
      alt = 'Compare Vehicles';
      link = `${path}/compare-vehicles`;

      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.compareVehicles"
            defaultMessage="Compare new vehicles"
            description="Compare New Vehicles"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    case "SEE_INCENTIVES":
      src = DISCOVER_INCENTIVES;
      alt = 'Discover Incentives';
      link = `${path}/incentives`;
      title = (
        <span>
          <FormattedMessage 
            id="homepage.linkCard.incentives"
            defaultMessage="See available incentives"
            description="HomePage Linkcard Incentives"
            values= {{
              lineBreak: <br />
            }}
          />
        </span>
      );
      break;
    default:
      return null;
  }

  return (
    <Link 
      to={link} 
      className="link-card" 
      target="_blank" 
      rel="noopener noreferrer"
    >
      <div className="link-card-content">
        <img src={src} className="link-icon" alt={alt} />
        <p className="h6">{title}</p>
      </div>
    </Link>
  );
};

export default LinkCard;

LinkCard.propTypes = {
  type: PropTypes.string
};
