import React, { useContext } from "react";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"
import ToolTip from "../../../components/shared/ToolTip/ToolTip"
import AllElectric from "../../assets/images/AllElectric.svg";
import PlugInHybrid from "../../assets/images/PlugInHybrid.svg";
import Eats from "../../../client_customizations/assets/images/Eats.svg"
import Rides from "../../../client_customizations/assets/images/Rides.svg"
import SelectVehicleMakeFilter from "../../../components/InputComponents/SelectVehicleMakeFilter/SelectVehicleMakeFilter"
import SelectVehicleAgeFilter from "../../../components/InputComponents/SelectVehicleAgeFilter/SelectVehicleAgeFilter"
import Switch from "react-switch"
import {FormattedMessage, useIntl} from 'react-intl';

const EVFilterControls = ({vehicles, usedEvs}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const fuelTypeFilterData = userPrefs.get("vehicleFuelTypeFilters");
  const classTypeFilterData = userPrefs.get("vehicleClassTypeFilter")

  const updateFuelTypeFilter = (checked, event, selectedKey) => {
    const newFilters = Object.keys(fuelTypeFilterData).reduce((acc,key) => {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !fuelTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
      vehicleFuelTypeFilters: newFilters
    });
  };

  const updateVehicleClassType = (checked, event, selectedKey) => {
    const newFilters = Object.keys(classTypeFilterData).reduce((acc,key) => {
      // Toggle selected key, set all others to false
      acc[key] = key === selectedKey ? !classTypeFilterData[key] : false;
      return acc;
    },
    {});

    userPrefs.set({
      vehicleClassTypeFilter: newFilters
    });
  };

  const renderedFuelTypeFilters = Object.keys(fuelTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      let filterIconSrc = "";
      switch (filterKey) {
        case "bev":
          filterName = intl.formatMessage ? intl.formatMessage({ id: "vehicle.allElectric", defaultMessage: "All Electric"}) : "All Electric";
          filterIconSrc = AllElectric;
          break;
        case "phev":
          filterName = intl.formatMessage ? intl.formatMessage({ id: "vehicle.plugInHybrid", defaultMessage: "Plug in Hybrid"}) : "Plug in Hybrid";
          filterIconSrc = PlugInHybrid;
          break;
        default:
      }

      return (
        <div key={i} className="filter-label">
          <span className="badge">
              <img src={filterIconSrc} className="padding-right" alt="Icon" />
              <label htmlFor={filterKey} className="name">{filterName}</label>
          </span>
          <div className="switch">
            <Switch
              id={filterKey}
              checked={fuelTypeFilterData[filterKey] ? true : false }
              onChange={updateFuelTypeFilter}
              onColor="#eee"
              offColor="#eee"
              onHandleColor="#000"
              height={15}
              width={34}
              uncheckedIcon={false}
              checkedIcon={false}
              handleDiameter={20}
              boxShadow={'0 2px 4px 1px #eee'}
            />
          </div>
          <div className="clearfix"></div>
        </div>
      );
    }
  );

  const renderedClassTypeFilters= Object.keys(classTypeFilterData).map(
    (filterKey, i) => {
      let filterName = "";
      let filterIconSrc = "";
      switch (filterKey) {
        case "ridesEats":
          filterName = intl.formatMessage ? intl.formatMessage({ id: "vehicle.rides", defaultMessage: "Uber rides + eats"}) : "Uber rides + eats";
          filterIconSrc = Rides;
          break;
        case "eatsOnly":
          filterName = intl.formatMessage ? intl.formatMessage({ id: "vehicle.eats", defaultMessage: "Uber eats only"}) : "Uber eats only";
          filterIconSrc = Eats;
          break;
        default:
      }

      return (
        <div key={i} className="filter-label">
          <span className="badge">
              <img src={filterIconSrc} className="padding-right" alt="Icon" />
              <label htmlFor={filterKey} className="name">{filterName}</label>
          </span>
          <div className="switch">
            <Switch
              id={filterKey}
              checked={classTypeFilterData[filterKey] ? true : false }
              onChange={updateVehicleClassType}
              onColor="#eee"
              offColor="#eee"
              onHandleColor="#000"
              height={15}
              width={34}
              uncheckedIcon={false}
              checkedIcon={false}
              background="#33333"
              handleDiameter={20}
              boxShadow={'0 2px 4px 1px #eee'}
            />
          </div>
          <div className="clearfix"></div>
        </div>
      );
    }
  );

  return (
    <div className="input-well">
      <p className="h2">
        <FormattedMessage 
          id="evfilter"
          defaultMessage="Filter"
          description="Filter Title"
        />
      </p>
      <form>
        <div className="form-group">
          <span className="label-style">
            <FormattedMessage 
              id="evfilter.fuel"
              defaultMessage="Fuel"
              description="Fuel"
            />
            <ToolTip
              message={intl.formatMessage ? intl.formatMessage({ id: "evfilter.fuelTooltip", defaultMessage: "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline."}) : "All-electric vehicles use electricity only. Plug-in hybrids can use electricity and gasoline."}
              id="fuel_tooltip"
            />
          </span>
          <div className="btn-block-container">{renderedFuelTypeFilters}</div>
        </div>
        <div className="form-group">
            <FormattedMessage 
              id="vehicle.class"
              defaultMessage="Vehicle Class"
              description="class"
            />
          <div className="btn-block-container">{renderedClassTypeFilters}</div>
        </div>
        {usedEvs ? 
        <>
        <div className="form-group">
          <SelectVehicleMakeFilter vehicles={vehicles} />
        </div>
        <div className="form-group">
          <SelectVehicleAgeFilter />
        </div>
        </>
        : null }
      </form>
      <br />
      <p className="legal-disclaimer">
        <FormattedMessage 
          id="evs.disclaimer"
          defaultMessage="Vehicles displayed may not reflect actual availability. {company} does not endorse or recommend any specific vehicle or car manufacturer."
          description="Vehicles Disclaimer"
          values= {{
            company: process.env.REACT_APP_FULL_COMPANY_NAME
          }}
        />
        
      </p>
    </div>
  );
};

export default EVFilterControls;
