import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import "./HomepageEVCard.scss";
import VehicleImage from "../../../components/VehicleImage/VehicleImage"
import { FormatCarModelAndTrim } from "../../../utils/Helpers/Format";
import { renderVehicleClassBadge, renderFuelTypeBadge } from '../../functions/vehicle/RenderBadges/RenderBadges'
import {FormattedMessage} from 'react-intl';

const HomepageEVCard = ({
  ev,
}) => {
  if (!ev) return null;

    let evImgs = ev.images ? ev.images.map( (n) =>
                  (n.url_thumbnail == null) ?  [] : 
                  (n.url_full == null) ?  []: 
                  [n]) : [];

  //Flatten evImgs into a single array                  
  const evImgSrc = [].concat.apply([], evImgs);
  
  const imgSrc = ev.images ? (ev.images.length >= 0 ? evImgSrc[0] : "") : "";

  let altText = "Go to " +(ev.make + " " + ev.model + " " + ev.trim).toString().trim(); 

  const renderCardBottom = (
      <div className="HomepageEVCardBottomWithLink">
        <Link
          to={`${"/vehicles/" + ev.handle}`}
          className="homepage-evcard-btn"
          style={{ outline: "none" }}
          target="_blank" 
          rel="noopener noreferrer"
        >
          <FormattedMessage
            id="evCard.seeDetails"
            defaultMessage="See Details"
            description="See Details"
          />
        </Link>
      </div>
  );

  return (
    <div className="homepage-ev-card">
      <div className="EVCardTop">
        <div className="badge-fuel-type">
          {renderVehicleClassBadge(ev)}
          {renderFuelTypeBadge(ev)}

        </div>
        <p className="h2" style={{ maxWidth: "70%" }}>
          {ev.make}
        </p>
        <p className="h3 mt-1">
          {FormatCarModelAndTrim(ev)}   
        </p>
        <div className="text-center">
          <VehicleImage image={imgSrc} size="thumb" alt={altText} />
        </div>
      </div>
      {renderCardBottom}
    </div>
  );
};

export default HomepageEVCard;

HomepageEVCard.propTypes = {
  ev: PropTypes.object,
  hasEvDetails: PropTypes.bool,
  hasIncentivesAndMatchScore: PropTypes.bool,
  hasLinkToEv: PropTypes.bool,
  hasLocallyAvailableHidden: PropTypes.bool
};
