import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import getCarCostOfOwnership from "../../../functions/vehicle/CostOfOwnership/getCarCostOfOwnership"
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import CostToOwnComparisonChart from "../CostsCharts/CostToOwnComparisonChart/CostToOwnComparisonChart";
import ShowHideButton from "../../../components/ShowHideButton/ShowHideButton";
import { FormatAsDollars, FormatCarName } from "./../../../utils/Helpers/Format";
import Uuid from "../../../utils/Uuid/Uuid"
import { FormattedMessage, useIntl } from 'react-intl';
import CostButtons from "../InputComponents/CostOfOwnershipButtons/CostofOwnershipButtons";


const CostToOwnComparison = ({
    cars,
    displayEvCostBreakdown,
    displayEvCostBreakdownOption,
    forceUserPrefsPresets,
    hasExtraPadding,
    comparison,
    insuranceData, 
    maintenanceData
}) => {
    const intl = useIntl()
    const userPrefs = useContext(UserPrefsContext);
    const [timeline, setTimeline] = useState("total")

    const [showCostBreakdown, setShowCostBreakdown] = useState(
        displayEvCostBreakdown
    );

    if (!cars || cars.length === 0) return null;

    cars = cars.filter(car => {
        return car !== null
    })

    const get = forceUserPrefsPresets ? userPrefs.getPreset : userPrefs.get;
    const yearsOfOwnership = get("monthsOfOwnership") / 12;

    const carsCosts = cars.map(car => {
        return getCarCostOfOwnership(
            car,
            userPrefs,
            insuranceData,
            maintenanceData
        )
    })

    const sortedCarCosts = [...carsCosts].sort((carCost1, carCost2) => {
        return carCost1.summed[timeline] - carCost2.summed[timeline]
    })
 
    let title
    if (cars.length === 2) {
        const costDelta = carsCosts[1].summed[timeline] - carsCosts[0].summed[timeline];

        const costDeltaText = costDelta < 0 ? intl.formatMessage({ id: "graph.moreExpensive", defaultMessage: "more expensive" }) : intl.formatMessage({ id: "graph.cheaper", defaultMessage: "cheaper" });
        const costDeltaTextEnding = "" 

        title = (
            <>
                <p className="h3 my-3 graph-title">
                    <FormattedMessage
                        id="graph.costOfOwnership.title"
                        defaultMessage="Cost of Ownership"
                        description="Graph Title"
                    />  
                </p>
                <p className="h3 graph-sub-title">
                    <FormattedMessage
                        id="graph.costOfOwnership.subTitle"
                        defaultMessage="The {carName} is {style} {costDeltaText} {costDeltaTextEnding}"
                        description="Graph Sub Title"
                        values={{
                            carName: FormatCarName(cars[0]),
                            style: <strong style={{ color: "rgb(33, 135, 57)", fontWeight: 800 }}>{FormatAsDollars(Math.abs(costDelta), intl.locale)}</strong>,
                            costDeltaText: costDeltaText,
                            costDeltaTextEnding: costDeltaTextEnding,
                        }}
                    />
                </p>
                <CostButtons
                    value={timeline}
                    handler={e => setTimeline(e.target.value)}
                />
            </>
        );
    } else {
        title = (
            <>
                <p className="h3 my-3 graph-title">
                    <FormattedMessage
                        id="graph.costOfOwnership.title"
                        defaultMessage="Cost of Ownership"
                        description="Graph Title"
                    />
                </p>
                <p className="h3 graph-sub-title">
                    <FormattedMessage
                        id="graph.costOfOwnership.subTitleThreeCars"
                        defaultMessage="The {carName} is the cheapest to own for {yearsOfOwnership} years"
                        description="Graph Sub Title"
                        values={{
                            carName: FormatCarName(sortedCarCosts[0].car),
                            yearsOfOwnership: yearsOfOwnership,
                        }}
                    />
                </p>
                <CostButtons
                    value={timeline}
                    handler={e => setTimeline(e.target.value)}
                />
            </>
        );
    }

    const carNames = comparison ? cars.map(car => FormatCarName(car)) : [[intl.formatMessage({ id: "graph.yourEV", defaultMessage: "Your EV Selection" }), FormatCarName(cars[0])], [
        intl.formatMessage({ id: "graph.similarGasVehicle", defaultMessage: "Similar Gas Vehicle" }), FormatCarName(cars[1])]]

    const descriptionRow = (description) => {
        return carsCosts.map(carCost => {
            return (
                <td key={Uuid()}>{FormatAsDollars(carCost[description][timeline], intl.locale)}</td>
            )   
        })
    }

    const descriptionRows =
        (
            <>
                <tr>
                    <th scope="row">
                        <FormattedMessage
                            id="graph.costOfOwnership.descriptionRow.vehicle"
                            defaultMessage="Vehicle"
                            description="Cost of Ownership Description Row"
                        />
                        {/* Vehicle */}
                    </th>
                    {descriptionRow("vehicle")}
                </tr>
                <tr>
                    <th scope="row">
                        <FormattedMessage
                            id="graph.costOfOwnership.descriptionRow.maintenance"
                            defaultMessage="Maintenance"
                            description="Cost of Ownership Description Row"
                        />
                    </th>
                    {descriptionRow("maintenance")}
                </tr>
                <tr>
                    <th scope="row">
                        <FormattedMessage
                            id="graph.costOfOwnership.descriptionRow.insurance"
                            defaultMessage="Insurance"
                            description="Cost of Ownership Description Row"
                        />
                    </th>
                    {descriptionRow("insurance")}
                </tr>
                <tr>
                    <th scope="row">
                        <FormattedMessage
                            id="graph.costOfOwnership.descriptionRow.electricity"
                            defaultMessage="Electricity"
                            description="Cost of Ownership Description Row"
                        />
                    </th>
                    {descriptionRow("electricity")}
                </tr>
                <tr>
                    <th scope="row">
                        <FormattedMessage
                            id="graph.costOfOwnership.descriptionRow.gasoline"
                            defaultMessage="Gasoline"
                            description="Cost of Ownership Description Row"
                        />
                    </th>
                    {descriptionRow("gasoline")}
                </tr>
            </>
        )

    const selectShowCostBreakdown = displayEvCostBreakdownOption ? (
        <div>
            <span className="mr-4">Why is the cost different?</span>
            <div
                className="form-group form-check text-center"
                style={{ display: "inline-block" }}
            >
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                    selected={showCostBreakdown}
                    onClick={() => setShowCostBreakdown(!showCostBreakdown)}
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                    <FormattedMessage
                        id="graph.showCostBreakdown"
                        defaultMessage="Show Cost Breakdown"
                        description="Show Cost Breakdown Title"
                    />
                </label>
            </div>
        </div>
    ) : null;

    const totalRow = (
        <tr>
            <th scope="row">
                <FormattedMessage
                    id="graph.costOfOwnership.totalRow"
                    defaultMessage="Total"
                    description="Cost of Ownership Total Row"
                />
            </th>
            {descriptionRow("summed")}
        </tr>
    );

    const carColumnNames = cars.map(car => {
        return (
            <th key={Uuid()} scope="col">{FormatCarName(car)}</th>
        )
    })

    return (
        <div
            className="input-well text-center"
            style={hasExtraPadding ? { padding: "2.5rem" } : { padding: "1rem" }}
        >
            {title}
            <div className="d-none d-lg-block d-xl-block">
                {selectShowCostBreakdown}
                <div>
                    <CostToOwnComparisonChart
                        timeline={timeline}
                        carNames={carNames}
                        carsCosts={carsCosts}
                        displayEvCostBreakdownOption={displayEvCostBreakdownOption}
                        displayEvCostBreakdown={showCostBreakdown}
                    />
                </div>
            </div>
            {hasExtraPadding && <br />}
            <ShowHideButton buttonText={intl.formatMessage({ id: "graph.graphValues", defaultMessage: "GRAPH VALUES" })} displayChildrenOnlyOnTablet>
                <br />
                <div className="row">
                    <div className="col-sm-12" style={{ paddingRight: "0px", paddingLeft: "0px" }}>
                        <table className="table table-sm table-bordered smaller">
                            <thead>
                                <tr>
                                    <th scope="col">
                                        <FormattedMessage
                                            id="graph.costOfOwnership.description"
                                            defaultMessage="Description"
                                            description="Cost of Ownership Description"
                                        />
                                    </th>
                                    {carColumnNames}
                                </tr>
                            </thead>
                            <tbody>
                                {descriptionRows}
                            </tbody>
                            <tfoot>{totalRow}</tfoot>
                        </table>
                    </div>
                </div>
            </ShowHideButton>
        </div>
    );
};

export default CostToOwnComparison;

CostToOwnComparison.propTypes = {
    car: PropTypes.object,
    displayFuelOnly: PropTypes.bool,
    displayEvCostBreakdown: PropTypes.bool,
    displayEvCostBreakdownOption: PropTypes.bool,
    forceUserPrefsPresets: PropTypes.bool,
    hasExtraPadding: PropTypes.bool
};