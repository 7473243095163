import React from "react";
import PropTypes from "prop-types";
import "./CostofOwnershipButtons.scss"
import { FormattedMessage } from "react-intl";

const CostButtons = ({
    value,
    handler,
}) => {


    return (
        <div>
            <div>
                <button
                    className={"btn-cost " + (value === "perMonth" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                    value="perMonth"
                    onClick={e => handler(e)}
                    >
                    <FormattedMessage
                        id="graph.costOfOwnership.buttonMonthly"
                        defaultMessage="Monthly"
                        description="Monthly Option"
                    />
                </button>
                <button
                    className={"btn-cost " + (value === "perYear" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                    value="perYear"
                    onClick={e => handler(e)}
                    >
                    <FormattedMessage
                        id="graph.costOfOwnership.buttonAnually"
                        defaultMessage="Anually"
                        description="Anually Option"
                    />
                </button>
                <button
                    className={"btn-cost " + (value === "total" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                    value="total"
                    onClick={e => handler(e)}
                    >
                    <FormattedMessage
                        id="graph.costOfOwnership.buttonOverall"
                        defaultMessage="Overall"
                        description="Overall Option"
                    />
                </button>
            </div>
        </div>

    );
};


export default CostButtons;

CostButtons.propTypes = {
    value: PropTypes.string,
    handler: PropTypes.func.isRequired
}