import React, { useContext } from "react";
import PropTypes from "prop-types";

import RenderItem from "../../RenderItem/RenderItem";
import calcCharging from "../../../functions/vehicle/Charging/calcCharging";
import calcEmissions from "./../../../functions/vehicle/Emissions/calcEmissions";
import isPHEV from "../../../functions/vehicle/isPHEV";

import {
  FormatAsThousands,
  FormatAsInt,
  FormatAsTime
} from "./../../../utils/Helpers/Format";
import IconTree from "./../../../client_customizations/assets/images/icons/tree.png";
import IconOil from "./../../../client_customizations/assets/images/icons/icon-barrel.svg";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { useIntl } from 'react-intl';

const CarDetails = ({ car, setUsedEv }) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const country = userPrefs.get("country");
  const milesDrivenAnnually = userPrefs.get("milesDrivenAnnually");
  const electricMilesPortionForPhev = userPrefs.get(
    "electricMilesPortionForPhev"
  );

  const detailedObjects = [
    {
      title: intl.formatMessage({ id: "vehicle.type", defaultMessage: "TYPE"}),
      val: car["form_factor"]
    },
    {
      title: intl.formatMessage({ id: "evs.minSeats", defaultMessage: "Minimum Seats"}),
      val: car["seats_min"]
    },
    {
      title: intl.formatMessage({ id: "vehicle.fuelType", defaultMessage: "FUEL TYPE"}),
      val: car["fuel"]
    },
    {
      title: intl.formatMessage({ id: "vehicle.electricRange", defaultMessage: "ELECTRIC RANGE"}),
      val: `${car["electric_range"]} ${country === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometersShort", defaultMessage: "km" }) : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" })}`,
      tooltip: setUsedEv ? intl.formatMessage({ id: "ev.cardetails.electricRange", defaultMessage: "To account for battery degradation, the range quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns."}) : null
    },
    {
      title: intl.formatMessage({ id: "evCard.totalRange", defaultMessage: "Total RANGE"}),
      val: `${car["total_range"]} ${country === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometersShort", defaultMessage: "km" }) : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" })}`,
      hide: !isPHEV(car)
    },
    {
      title: country === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometersPerThirtyFastCharge", defaultMessage: "KILOMETERS PER 30 MIN OF FAST CHARGING" }) : intl.formatMessage({ id: "vehicle.milesPerThirtyFastCharge", defaultMessage: "MILES PER 30 MIN OF FAST CHARGING"}),
      val:
        `~ ${FormatAsInt(
          calcCharging.milesPerHalfHourOfCharge(
            car.electric_efficiency,
            "dc_fast_charger"
          )
        )}
        ${country === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometersShort", defaultMessage: "km" }) : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" })}`,
      tooltip:
        `${intl.formatMessage({ id: "ev.carDetails.fastChargingTooltip", defaultMessage: "DC Fast Charger Speed:"})}
        ${calcCharging.speedInKw("dc_fast_charger")}
        kW`,
      hide: isPHEV(car)
    },
    {
      title: intl.formatMessage({ id: "vehicle.timeToChargeLvlTwo", defaultMessage: "TIME TO CHARGE - LEVEL 2"}),
      val:
        "~ " +
        FormatAsTime(
          calcCharging.timeForFullBattery(
            car.battery_capacity,
            car.ac_charging_power,
            "level_2"
          )
        ),
      tooltip:
        `${intl.formatMessage({ id: "ev.carDetails.levelTwoChargerSpeedTooltip", defaultMessage: "Level 2 Charger Speed:"})} ${calcCharging.speedInKw("level_2")} kW`
    },
    {
      title: intl.formatMessage({ id: "vehicle.batterySize", defaultMessage: "BATTERY SIZE"}),
      val: car["battery_capacity"] + " kWh",
      tooltip: country === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? setUsedEv ? intl.formatMessage({ id: "ev.carDetails.kilometerUsedBatterySizeTooltip", defaultMessage: "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 kilometers, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns." }) : intl.formatMessage({ id: "ev.carDetails.kilometerBatterySizeTooltip", defaultMessage: "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 kilometers, depending on the vehicle. A typical laptop battery can hold less than 0.1 kWh." }) : setUsedEv ? intl.formatMessage({ id: "ev.carDetails.usedBatterySizeTooltip", defaultMessage: "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. To account for battery degradation for a used EV, the battery size quoted here is an estimation based on the age of the vehicle and normal driving/charging patterns."}) : intl.formatMessage({ id: "ev.carDetails.batterySizeTooltip", defaultMessage: "One kilowatt-hour (kWh) is enough energy to drive 1 to 4 miles, depending on the vehicle. A typical laptop battery can hold less than 0.1 kWh."})
    },
    {
      title: intl.formatMessage({ id: "vehicle.coTwoEmissions", defaultMessage: "CO2 EMISSIONS REDUCTION"}),
      val:
        FormatAsThousands(
          calcEmissions.emissionReduction(
            car,
            milesDrivenAnnually,
            electricMilesPortionForPhev,
            country
          )
        ) + " " + (country === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "kgperyear", defaultMessage: "kg/yr" }) : intl.formatMessage({ id: "lbsperyear", defaultMessage: "lbs/yr" }))
    },
    {
      title: intl.formatMessage({ id: "ev.carDetails.treesPlanted", defaultMessage: "Trees Planted"}),
      val: (
        <span>
          <img alt="Tree Icon" src={IconTree} style={{ width: "15px" }} />{" "}
          {FormatAsThousands(
            calcEmissions.treesPlanted(
              car,
              milesDrivenAnnually,
              electricMilesPortionForPhev,
              country
            )
          )}
        </span>
      )
    },
    {
      title: intl.formatMessage({ id: "ev.carDetails.gasolineSaved", defaultMessage: "Gasoline Saved"}),
      val: (
        <span>
          <img alt="Oil Icon" src={IconOil} style={{ width: "15px" }} />{" "}
          {FormatAsThousands(
            calcEmissions.fuelSaved(
              car,
              milesDrivenAnnually,
              electricMilesPortionForPhev,
              country
            )
          )}{" "}
          {country === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "literperyear", defaultMessage: "L/yr" }) : intl.formatMessage({ id: "galperyear", defaultMessage: "gal/yr" })}
        </span>
      )
    }
  ];

  let renderedCount = 0;
  let details = detailedObjects.map(obj => {
    if (!obj.hide) renderedCount += 1;

    return obj.hide ? null : (
      <div className="col-6" key={renderedCount}>
        <RenderItem
          title={obj.title}
          value={obj.val}
          tooltipText={obj.tooltip}
        />
      </div>
    );
  });

  return <div className="row">{details}</div>;
};

export default CarDetails;

CarDetails.propTypes = {
  car: PropTypes.object
};
