import React, { useContext } from "react";
import PropTypes from "prop-types";
import getCarCostOfOwnership from "../../functions/vehicle/CostOfOwnership/getCarCostOfOwnership"
import UserPrefsContext from "../../context/UserPrefs/UserPrefsContext";
import ShowHideButton from "../ShowHideButton/ShowHideButton";
import { FormatAsDollars, FormatCarName } from "../../utils/Helpers/Format";
import "chartjs-plugin-datalabels";
import { HorizontalBar } from "react-chartjs-2";
import {FormattedMessage, useIntl} from 'react-intl';

const CHART_JS_OPTIONS = {
  maintainAspectRatio: false,
  tooltips: {
    callbacks: {
      label: function(tooltipItem, data) {
        var value =
          data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
        var label = data.datasets[tooltipItem.datasetIndex].label || "";
        value = FormatAsDollars(value);
        return " " + label + ": " + value;
      },
      title: function(tooltipItems, data) {
        let total = tooltipItems.reduce(function(acc, item) {
          return acc + item.xLabel;
        }, 0);
        return tooltipItems[0].yLabel + ": " + FormatAsDollars(total);
      }
    }
  },
  scales: {
    xAxes: [
      {
        stacked: true,
        ticks: {
          callback: function(value) {
            value = FormatAsDollars(value);
            return value;
          },
          beginAtZero: true
        }
      }
    ],
    yAxes: [
      {
        stacked: true,
        ticks: {
          font: {
            weight: 700
          }
        }
      }
    ]
  },
  legend: {
    position: "bottom",
    onClick: function(e) {
      e.stopPropagation();
    }
  },
  layout: {
    padding: {
      right: 75
    }
  },
  plugins: {
    datalabels: {
      formatter: (value, ctx) => {
        let datasets = ctx.chart.data.datasets;
        if (ctx.datasetIndex === datasets.length - 1) {
          let sum = 0;
          datasets.forEach(dataset => {
            sum += dataset.data[ctx.dataIndex];
          });
          return FormatAsDollars(sum);
        } else {
          return "";
        }
      },
      align: "end",
      anchor: "end",
      color: "#333333",
      font: {
        weight: 700
      }
    }
  }
};

const MonthlyFuelCostChart = ({
  cars,
  forceUserPrefsPresets,
  hasExtraPadding,
  comparison,
  insuranceData,
  maintenanceData
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);

  if (!cars || cars.length === 0) return null;

  cars = cars.filter( car => {
    return car !== null
  })

  const carsCosts = cars.map(car => {
    return getCarCostOfOwnership(
      car,
      userPrefs,
      insuranceData,
      maintenanceData
    )
  })

  const sortedCarCosts = [...carsCosts].sort((carCost1, carCost2) => {
    let cost1 = carCost1.electricity.perMonth + carCost1.gasoline.perMonth
    let cost2 = carCost2.electricity.perMonth + carCost2.gasoline.perMonth
    return cost1 - cost2
  })

  const subTitle = cars => {
    if (cars.length === 2) {
      const costDelta =
      carsCosts[1].electricity.perMonth +
      carsCosts[1].gasoline.perMonth -
      carsCosts[0].electricity.perMonth -
      carsCosts[0].gasoline.perMonth;
      const costDeltaText = costDelta < 0 ? intl.formatMessage({ id: "graph.moreExpensive", defaultMessage: "more expensive"}) : intl.formatMessage({ id: "graph.cheaper", defaultMessage: "cheaper"});
  
      return (
        <p className="h3 my-3 graph-sub-title">
          <FormattedMessage
            id="graph.monthlyCostToFill.subTitle"
            defaultMessage="The {carName} is {style} {costDeltaText} to fill up monthly"
            description="Graph Sub Title"
            values= {{
              carName: FormatCarName(cars[0]),
              style: <strong style={{ color: "rgb(33, 135, 57)", fontWeight: 800 }}>{FormatAsDollars(Math.abs(costDelta))}</strong>,
              costDeltaText: costDeltaText,
            }}
          />
        </p>
      );
    } else {
      return (
        <p className="h3 my-3 graph-sub-title">
          <FormattedMessage
            id="graph.monthlyCostToFill.subTitleThreeCars"
            defaultMessage="The {carName} is cheapest to fill up monthly"
            description="Graph Sub Title"
            values= {{
              carName: FormatCarName(sortedCarCosts[0].car),
            }}
          />
        </p>
      )
    }
  }

  let title = (
    <>
      <p className="h3 my-3 graph-title">
        <FormattedMessage
          id="graph.monthlyCostToFill.title"
          defaultMessage="Cost to Fill Up Monthly"
          description="Graph Title"
        />
      </p>
      {subTitle(cars)}
    </>
  )

  const carNames = comparison ? cars.map(car => FormatCarName(car)) : [[intl.formatMessage({ id: "graph.yourEV", defaultMessage: "Your EV Selection"}), FormatCarName(cars[0])],[
    intl.formatMessage({ id: "graph.similarGasVehicle", defaultMessage: "Similar Gas Vehicle"}), FormatCarName(cars[1])]] 

  const chartData = {
    labels: carNames,
    datasets: [
      {
        label: intl.formatMessage({ id: "graph.costOfOwnership.chart.electricity", defaultMessage: "Electricity"}),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_ELECTRICITY}`,
        data: carsCosts.map(carCost => carCost.electricity.perMonth)
      },
      {
        label: intl.formatMessage({ id: "graph.costOfOwnership.chart.gasoline", defaultMessage: "Gasoline"}),
        backgroundColor: `#${process.env.REACT_APP_COMPONENTS_COST_TO_OWN_COMPARISION_CHART_BAR_COLOR_GASOLINE}`,
        data: carsCosts.map(carCost => carCost.gasoline.perMonth)
      }
    ]
  };

  const graphValueVehicleRows = cars.map((car, i) => {
    const carCost = carsCosts[i]
    return (
      <tr key={i}>
        <th scope="row">{FormatCarName(car)}</th>
        <td>{FormatAsDollars(carCost.electricity.perMonth)}</td>
        <td>
          {FormatAsDollars(
            carCost.gasoline.perMonth
          )}
        </td>
        <td>
        {FormatAsDollars(carCost.electricity.perMonth + carCost.gasoline.perMonth)}
        </td>
      </tr>
    )
  })

  return (
    <div
      className="input-well text-center"
      style={hasExtraPadding ? { padding: "2.5rem" } : { padding: "1rem" }}
    >
      {title}
      <div className="d-none d-lg-block d-xl-block">
        <div>
          <HorizontalBar
            data={chartData}
            height={212}
            type="horizontalBar"
            options={CHART_JS_OPTIONS}
          />
        </div>
      </div>
      {hasExtraPadding && <br />}
      <ShowHideButton buttonText={intl.formatMessage({ id: "graph.graphValues", defaultMessage: "Graph Values"})} displayChildrenOnlyOnTablet>
        <br />
        <div className="row">
          <div className="col-sm-12">
            <table className="table table-sm table-bordered smaller">
              <thead>
                <tr>
                  <th scope="col">
                    <FormattedMessage
                      id="vehicles"
                      defaultMessage="Vehicle"
                      description="Monthly Fuel Cost Description Row"
                    />
                  </th>
                  <th scope="col">
                    <FormattedMessage
                      id="graph.costOfOwnership.descriptionRow.electricity"
                      defaultMessage="Electricity"
                      description="Monthly Fuel Cost Description Row"
                    />
                  </th>
                  <th scope="col">
                  <FormattedMessage
                    id="graph.costOfOwnership.descriptionRow.gasoline"
                    defaultMessage="Gasoline"
                    description="Monthly Fuel Cost Description Row"
                  />
                  </th>
                  <th scope="col">
                    <FormattedMessage
                      id="total"
                      defaultMessage="Total"
                      description="Monthly Fuel Cost Description Row"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {graphValueVehicleRows}
              </tbody>
            </table>
          </div>
        </div>
      </ShowHideButton>
    </div>
  );
};

export default MonthlyFuelCostChart;

MonthlyFuelCostChart.propTypes = {
  car: PropTypes.object,
  forceUserPrefsPresets: PropTypes.bool,
  hasExtraPadding: PropTypes.bool
};
