import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext"
import MonthlyFuelCostChart from "../../../components/CostsCharts/MonthlyFuelCostChart";
import FuelRangeChart from "../../../components/CostsCharts/FuelRangeChart";
import CostToOwnComparison from "../CostsCharts/CostToOwnComparison";
import CostOfOwnershipOptions from "../../../components/CostOfOwnershipOptions/CostOfOwnershipOptions";
import { FormatCarName } from "../../../utils/Helpers/Format";
// import ShowHideButton from "../ShowHideButton/ShowHideButton";
import ShowHideButtonSpecial from "../ShowHideButtonSpecial/ShowHideButtonSpecial"
import CalcPanels from "../../../components/CostOfOwnership/CalcPanels/CalcPanels"
import RenderAssumption from "../../../components/RenderAssumption/RenderAssumption";
import {
    SALES_TAX,
    GASOLINE_PRICE_IN_DOLLARS_PER_GAL,
    DOWN_PAYMENT,
    ELECTRICITY_RATE_IN_DOLLARS_PER_KWH
} from "../../data/assumptions/ASSUMPTIONS"
import { FormattedMessage, useIntl } from 'react-intl';
import BreakevenChart from "../../../components/CostsCharts/BreakevenChart";
import { loadInsuranceData } from "../../../functions/vehicle/CostOfOwnership/api/loadInsuranceData";
import { loadMaintenanceData } from "../../../functions/vehicle/CostOfOwnership/api/loadMaintenanceData";

const CostOfOwnership = ({ cars, title, comparison, usedEv }) => {
    const intl = useIntl()
    const userPrefs = useContext(UserPrefsContext);
    const [isLoading, setIsLoading] = useState(true);
  const [insuranceData, setInsuranceData] = useState(null);
  const [maintenanceData, setMaintenanceData] = useState(null);

  useEffect(() => {
    if (cars && cars.length > 0) {
      loadInsuranceData(
        cars[0].handle,
        userPrefs,
        setInsuranceData,
        setIsLoading
      );
      loadMaintenanceData(cars, userPrefs, setMaintenanceData, setIsLoading);
    }
  }, [cars, userPrefs]);

    if (!cars || cars.length === 0) return null;

    const car = cars[0]
    const equivalentGasVehicle = cars[1];

    SALES_TAX.title = intl.formatMessage({ id: "assumption.salesTax", defaultMessage: "Sales Tax" })
    SALES_TAX.value = userPrefs.get("salesTax");
    SALES_TAX.valueAsFormatted =
      (userPrefs.get("salesTax") * 100).toFixed(2) + "%";
    GASOLINE_PRICE_IN_DOLLARS_PER_GAL.title = intl.formatMessage({ id: "assumption.gasolinePrice", defaultMessage: "Price of Gasoline" })
    DOWN_PAYMENT.title = intl.formatMessage({ id: "assumption.downPayment", defaultMessage: "Down Payment" })
    DOWN_PAYMENT.valueAsFormatted = intl.formatMessage({ id: "assumption.downPaymentValue", defaultMessage: "10% of MSRP + Tax" })
    ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.title = intl.formatMessage({ id: "assumption.electricityRate", defaultMessage: "Electricity Rate" })
    ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted = process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE ? `$${userPrefs.get("electricityRate")}/kWh` : ELECTRICITY_RATE_IN_DOLLARS_PER_KWH.valueAsFormatted

    return (
      <section className="container" id="CostOfOwnership">
        <div className="CostOfOwnership">
          <div className="row">
            <div className={`col-sm-12 ${comparison ? `` : `text-center`}`}>
              <p className={comparison ? `h3 text-left` : `h2 mb-3 padded`}>
                {title || (
                  <FormattedMessage
                    id="ev.compareGasVehicle"
                    defaultMessage="Compare the {car} to a similar gas vehicle, the {gasCar}"
                    description="Description of Colton Recharged"
                    values={{
                      car: FormatCarName(car),
                      gasCar: FormatCarName(equivalentGasVehicle),
                    }}
                  />
                )}
              </p>
              {comparison || usedEv ? null : (
                <Link
                  to={`${userPrefs.get("additionalParams")}/compare-vehicles`}
                >
                  <button style={{ margin: "1.25rem" }} className="btn-ae">
                    {intl.formatMessage({
                      id: "ev.compareOtherVehicles",
                      defaultMessage: "COMPARE OTHER VEHICLES",
                    })}
                  </button>
                </Link>
              )}
            </div>
          </div>
          {comparison ? null : (
            <div className="modal-title">
              {intl.formatMessage({
                id: "graph.title.costAnalysis",
                defaultMessage: "Cost Analysis",
              })}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-4 col-lg-3" style={{ textAlign: "left" }}>
            <CostOfOwnershipOptions cars={cars} usedEv={usedEv} />
            <br />
            <div className="input-well d-none d-md-block">
              <p
                className="RenderItemTitle"
                style={{ fontFamily: "Uber-Move-Medium", marginBottom: 10 }}
              >
                <FormattedMessage
                  id="graph.assumptions"
                  defaultMessage="Assumptions"
                  description="Assumptions"
                />
              </p>
              {!process.env.REACT_APP_SLIDE_SALES_TAX && (
                <RenderAssumption assumption={SALES_TAX} />
              )}{" "}
              {/* <RenderAssumption assumption={GASOLINE_PRICE_IN_DOLLARS_PER_GAL} /> */}
              {userPrefs.get("purchaseMethod") === "cash" || usedEv ? null : (
                <RenderAssumption assumption={DOWN_PAYMENT} />
              )}
              <RenderAssumption
                assumption={ELECTRICITY_RATE_IN_DOLLARS_PER_KWH}
              />
            </div>
            {!comparison && (
              <>
                <p className="text-left disclaimer">
                  <FormattedMessage
                    id="ev.carDetails.compareText"
                    defaultMessage="We selected the {gasVehicle} based on its
                                proximity to the {vehicle} based on brand, size, and price."
                    description="Compare Vehicle Text"
                    values={{
                      gasVehicle: FormatCarName(equivalentGasVehicle),
                      vehicle: FormatCarName(car),
                    }}
                  />
                </p>
                <p className="text-left disclaimer">
                  <FormattedMessage
                    id="footer.generalDisclaimer"
                    defaultMessage="Vehicles displayed may not reflect actual availability. Uber does not endorse or recommend any specific vehicle or car manufacturer.
                                Uber is not responsible for the products or services offered by third -parties, including this cost-savings calculator, and makes no claims related to the effectiveness of such or to potential savings."
                    description="General Disclaimer Footer"
                  />
                </p>
              </>
            )}
          </div>

          <div
            id="costs-chart-areas"
            aria-live="polite"
            aria-atomic="true"
            role="region"
            style={{ position: "absolute", top: "-9999px" }}
          >
            <span>
              <FormattedMessage
                id="results.updated"
                defaultMessage="Results have been updated as of "
                description="Results have been updated as of "
              />
              {new Date().toLocaleString()}.
            </span>
          </div>
          <div className="col-md-8 col-lg-9">
            {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_OWN_COMPARISON &&
            !isLoading &&
            insuranceData &&
            maintenanceData ? (
              <CostToOwnComparison
                cars={cars}
                hasExtraPadding
                comparison={comparison}
                insuranceData={insuranceData}
                maintenanceData={maintenanceData}
              />
            ) : null}
            <br />
            {process.env.REACT_APP_PAGES_HOME_GRAPH_COST_TO_FILL_ENABLED &&
            isLoading &&
            insuranceData &&
            maintenanceData ? (
              <MonthlyFuelCostChart
                cars={cars}
                comparison={comparison}
                insuranceData={insuranceData}
                maintenanceData={maintenanceData}
              />
            ) : null}
            <br />
            {process.env.REACT_APP_PAGES_HOME_GRAPH_BUDGT_ENABLED ? (
              <FuelRangeChart car={car} />
            ) : null}
            <br />
            {process.env.REACT_APP_PAGES_BREAKEVEN_GRAPH &&
            !isLoading &&
            insuranceData &&
            maintenanceData ? (
              <BreakevenChart
                cars={cars}
                insuranceData={insuranceData}
                maintenanceData={maintenanceData}
              />
            ) : null}
          </div>
          <div className="col-sm-12 text-center">
            <br />
            <ShowHideButtonSpecial
              buttonText={` ${intl.formatMessage({
                id: "sources",
                defaultMessage: "Sources",
              })}`}
              displayAlertOnlyOnMobile
            >
              <div className="input-well">
                {!isLoading && insuranceData && maintenanceData ? (
                  <CalcPanels
                    cars={cars}
                    insuranceData={insuranceData}
                    maintenanceData={maintenanceData}
                  />
                ) : null}
              </div>
            </ShowHideButtonSpecial>
          </div>
        </div>
      </section>
    );
};

export default CostOfOwnership;

CostOfOwnership.propTypes = {
    car: PropTypes.object
};
