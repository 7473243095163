import React, { useState } from "react";
import PropTypes from "prop-types";
import { Popover, PopoverBody } from "reactstrap";
import { useIntl } from "react-intl";
import "./MatchScorePopover.scss"

const MatchScorePopover = ({ score, large }) => {
  const [showPopover, setPopover] = useState(false);
  const intl = useIntl();
  const [id] = useState(
    "key" +
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
  );
  if (!score) return null;
  const value = score.total ? score.total : 0;
  const range = score.range ? score.range : 0;
  const charging = score.charging ? score.charging : 0;
  const seats = score.seats ? score.seats : 0;
  const budgetAdjustment = score.budgetAdjustment ? score.budgetAdjustment : 0;

  let MatchScorePopoverValueClass = value > 85 ? "ScoreValueGreaterThanEightyFive" : value > 70 ? "ScoreValueGreaterThanSeventy" : "ScoreValueLessThanSeventy";

  let renderTable = (
    <table className="table table-borderless">
      <thead>
        <tr>
          <th scope="col" />
          <th scope="col">
            {intl.formatMessage({ id: "matchScore.maxScore", defaultMessage: "Max Score"})}
          </th>
          <th scope="col">
            {intl.formatMessage({ id: "matchScore.yourScore", defaultMessage: "Your Score"})}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th scope="row">
            {intl.formatMessage({ id: "matchScore.range", defaultMessage: "Range" })}
          </th>
          <td>45</td>
          <td>{range}</td>
        </tr>
        <tr>
          <th scope="row">
            {intl.formatMessage({ id: "matchScore.charging", defaultMessage: "Charging" })}
          </th>
          <td>30</td>
          <td>{charging}</td>
        </tr>
        <tr>
          <th scope="row">
            {intl.formatMessage({ id: "matchScore.seats", defaultMessage: "Seats" })}
          </th>
          <td>25</td>
          <td>{seats}</td>
        </tr>
        <tr>
          <th scope="row">
            {intl.formatMessage({ id: "matchScore.budget", defaultMessage: "Budget" })}&nbsp;
            {intl.formatMessage({ id: "matchScore.adjustment", defaultMessage: "Adjustment" })}
            </th>
          <td>-</td>
          <td>- {budgetAdjustment === 0 ? "" : budgetAdjustment}</td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th scope="row">
            {intl.formatMessage({ id: "matchScore.totalScore", defaultMessage: "Total Score" })}
          </th>
          <td>100</td>
          <td className="MatchScore">
            <strong className={MatchScorePopoverValueClass}>{value}</strong>
          </td>
        </tr>
      </tfoot>
    </table>
  );

  let renderPopover = showPopover ? (
    <Popover
      placement="bottom"
      isOpen={showPopover}
      target={id}
      className="MatchScorePopover"
    >
      <PopoverBody className="renderRowOfData">
        <p className="h4">
          {intl.formatMessage({ id: "matchScore.title", defaultMessage: "Title" })}
        </p>
        <p>
          {intl.formatMessage({ id: "matchScore.popover", defaultMessage: "Your Match Score is based on your selected preferences." })}
        </p>
        {renderTable}
      </PopoverBody>
    </Popover>
  ) : (
    ""
  );

  let renderOffscreenText = showPopover ? (
    <div style={{ position: "absolute", left: "-9999px" }}>{renderTable}</div>
  ) : null;

  let classes = (large ? "h2 RenderItemValue" : "RenderItemValue");
  
  return (
    <div className="MatchScore" >
      <p
        id={id}
        className={classes} 
        onMouseOver={e => setPopover(true)}
        onMouseLeave={e => setPopover(false)}
        onFocus={e => setPopover(true)}
        onBlur={e => setPopover(false)}
        tabIndex="0"
      >
        <span className={MatchScorePopoverValueClass} style={{ padding: '2px' }}>
        {value}
        </span>
        <span className="denominator">&nbsp;/100</span>
      </p>
      {renderPopover}
      {renderOffscreenText}
    </div>
  );
};

export default MatchScorePopover;

MatchScorePopover.propTypes = {
  score: PropTypes.object,
  large: PropTypes.bool
};
