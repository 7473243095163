import React from "react";
import PropTypes from "prop-types";
import ToolTip from "../../../../components/shared/ToolTip/ToolTip";
import "./HomeChargeButtons.scss"
import { FormattedMessage } from "react-intl";

const HomeButtons = ({
    id,
    value,
    label,
    handler,
    tooltip
}) => {
    

    return (
        <div>
            <div className="">
                {label}
                {tooltip && <ToolTip id={id + "_tooltip"} message={tooltip} />}
            </div>
            <div className="charge-btns">
                <button 
                    className={"charge-btn first " + (value === "level_2" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                    value="level_2"
                    onClick={(e) => handler(e)}>
                    <FormattedMessage
                        id="yes"
                        defaultMessage="yes"
                        description="yes Option"
                    />
                </button>
                <button 
                    className={"charge-btn " + (value === "level_2" ? "buttonInactiveStyle" : "buttonActiveStyle")}
                    value="no_charger"
                    onClick={(e) => handler(e)}>
                    <FormattedMessage
                        id="no"
                        defaultMessage="no"
                        description="no Option"
                    />
                </button>
            </div>
        </div>
 
    );
};


export default HomeButtons;

HomeButtons.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.string,
    handler: PropTypes.func.isRequired
}
