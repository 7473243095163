import React, { useContext } from 'react'
import UserPrefsContext from '../../../context/UserPrefs/UserPrefsContext';

import { Link } from "react-router-dom";
import "./NewUsedEVFilter.scss";
import { FormattedMessage } from "react-intl";

const NewUsedEVFilter = ({value}) => {
    const userPrefs = useContext(UserPrefsContext);

    return (
        <div className="filter-btns">
            <Link to={`${userPrefs.get("additionalParams")}/vehicles`}>
                <button 
                    className={"ev-filter-btn " + (value === "new" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                    value="new" 
                    >
                    <FormattedMessage
                        id="vehicles.new"
                        defaultMessage="New"
                        description="New Option"
                    />
                </button>
            </Link>
            <Link to={`${userPrefs.get("additionalParams")}/used-vehicles`}> 
                <button 
                    className={"ev-filter-btn " + (value === "used" ? "buttonActiveStyle" : "buttonInactiveStyle")}
                    value="used" 
                    >
                    <FormattedMessage
                        id="vehicles.used"
                        defaultMessage="Used"
                        description="Used Option"
                    />
                </button>
            </Link>
        </div>
    )
}
export default NewUsedEVFilter
