import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import { FormatAsDollars } from "../../../../utils/Helpers/Format";

import Range from "../../../../components/shared/InputElements/Range";
import {useIntl} from 'react-intl';


const SlideHouseholdIncome = ({
  id = "household-income-slider",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage({ id: "incentives.householdIncome", defaultMessage: "Household Income"})
  const descriptionFn = val => {
    return val === 350000 ? "$350,000+" : FormatAsDollars(val, intl.locale);
  };
  return (
    <Range
      id={id}
      value={userPrefs.get("householdIncome")}
      description={descriptionFn}
      label={label}
      rangeMin={5000}
      rangeMax={350000}
      rangeStep={5000}
      handler={e => userPrefs.set({ householdIncome: e.target.value })}
      {...rest}
    />
  );
};

export default SlideHouseholdIncome;

SlideHouseholdIncome.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
