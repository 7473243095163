import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";


import HomeButtons from "../HomeChargeButtons/HomeChargeButtons";
import { useIntl } from 'react-intl';


const SelectChargerLevel = ({
    id = "select-charger-level",
    ...rest
}) => {
    const intl = useIntl()
    const userPrefs = useContext(UserPrefsContext);
    const label = intl.formatMessage ? intl.formatMessage({ id: "evs.homeChargingAvailability", defaultMessage: "Home Charging Availability" }) : "Home Charging Availability"
    const tooltip = intl.formatMessage ? intl.formatMessage({ id: "evs.homeChargingAvailabilityTooltip", defaultMessage: "More electric vehicles will be convenient for you if you can charge quickly at home." }) : "More electric vehicles will be convenient for you if you can charge quickly at home."
    return (
        <>
            <HomeButtons
                id={id}
                label={label}
                tooltip={tooltip}
                value={userPrefs.get("chargerLevel")}
                handler={e => userPrefs.set({ chargerLevel: e.target.value })}
            />
        </>
    );
};

export default SelectChargerLevel;

SelectChargerLevel.propTypes = {
    id: PropTypes.string,
    label: PropTypes.string,
    tooltip: PropTypes.string
};
