const pages = ["homePage", "vehicles", "incentives", "used-vehicles", "compare-vehicles", "vehicles/:evId", "used-vehicles/:evId"]

const INTERNATIONAL_TABS = {}

pages.forEach(page => {
    if (page === "homePage") {
        page = ""
    }
    INTERNATIONAL_TABS[page] = [
        {
            route: `/${page}`,
            language: "EN",
            key: "US English"
        },

    ]
    if (process.env.REACT_APP_CANADA_DEPLOYMENT) {
        INTERNATIONAL_TABS[page].push(
            {
                route: `/ca/${page}`,
                language: "EN",
                key: "Canada English"
            },
            {
                route: `/ca/fr-ca/${page}`,
                language: "FR",
                key: "Canada French"
            }
        )
    }
})

export default INTERNATIONAL_TABS
