import React from 'react'
import IconBEV from "../../../assets/images/icons/icon-electric.svg"
import IconPHEV from "../../../assets/images/icons/icon-hybrid.svg"
import Eats from "../../../assets/images/Eats.svg"
import Rides from "../../../assets/images/Rides.svg"
import isBEV from '../../../../functions/vehicle/isBEV'

export const renderFuelTypeBadge = (ev) => {
    return isBEV(ev) ?
        <span>
            <img alt="All-Electric" src={IconBEV} />
        </span> :   
        <span>
            <img alt="Hybrid" src={IconPHEV} />
        </span>
};

export const renderVehicleClassBadge = (ev) => {
    return ev.doors > 3 && ev.seats_min > 4 ?
        <span>
            <img className="class-badge" alt="Uber Rides & Eats" src={Rides} />
        </span> :
        <span>
            <img className="class-badge" alt="Uber Eats only" src={Eats} />
        </span>
};
