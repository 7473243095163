import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from 'react-intl';

const ShowHideButton = ({
  children,
  buttonText,
  displayChildrenOnlyOnTablet,
  displayChildrenOnlyOnMobile,
  displayAlertOnlyOnMobile
}) => {
  const [showChildren, setShowChildren] = useState(false);

  const toggleButton = () => {
    setShowChildren(!showChildren);
  };
  const intl = useIntl()


  let renderChildren = showChildren ? children : null;
  let renderButtonText = showChildren
    ? intl.formatMessage ? intl.formatMessage({ id: "graph.hide", defaultMessage: "Hide"}) + " " + buttonText: "Hide" + buttonText
    : intl.formatMessage ? intl.formatMessage({ id: "graph.show", defaultMessage: "Show"}) + " " + buttonText: "Show" + buttonText;

  let button = (
    <button
      type="button"
      className="btn arrow"
      onClick={toggleButton}
      style={{
        border: "1px solid #DBDBDB",
        margin: "0 5px 10px 0",
        fontWeight: "400",
        fontSize: "0.85rem"
      }}
    >
      {renderButtonText}
    </button>
  );

  let desktopClasses = "show-full-list-container d-none d-md-block";
  let mobileClasses = "d-block d-md-none";
  if (displayChildrenOnlyOnTablet) {
    desktopClasses = "show-full-list-container d-none d-lg-block";
    mobileClasses = "d-block d-lg-none";
  }

  return displayChildrenOnlyOnTablet ||
    displayChildrenOnlyOnMobile ||
    displayAlertOnlyOnMobile ? (
    <>
      <div className={desktopClasses}>
        {button}
        {renderChildren}
      </div>
      <div className={mobileClasses}>
        {displayChildrenOnlyOnMobile && children}
        {displayChildrenOnlyOnTablet && children}
        {displayAlertOnlyOnMobile && (
          <div className="input-well">
            <p>
              <FormattedMessage
                  id="ev.carDetails.mobile"
                  defaultMessage="For more details including charts {amp} calculations, please view this page on a desktop."
                  description="Car Details Mobile subtitle"
                  values={{
                    amp: "&",
                  }}
                />
            </p>
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="show-full-list-container">
      {button}
      {renderChildren}
    </div>
  );
};

export default ShowHideButton;

ShowHideButton.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  displayChildrenOnlyOnTablet: PropTypes.bool,
  displayChildrenOnlyOnMobile: PropTypes.bool,
  displayAlertOnlyOnMobile: PropTypes.bool
};
