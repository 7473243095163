import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const ModalComponent = ({ children, submitText, buttonText, titleText }) => {
  const [showModal, setModal] = useState(false);
  const toggle = () => {
    setModal(!showModal);
  };

  let renderFooter = submitText ? (
    <ModalFooter>
      <Button color="btn btn-ae" onClick={toggle}>
        {submitText}
      </Button>
    </ModalFooter>
  ) : null;

  return (
    <div>
      <button onClick={toggle} className="btn btn-ae centered" type="button">
        {buttonText}
      </button>
      <Modal isOpen={showModal} toggle={toggle}>
        <ModalHeader toggle={toggle}>{titleText}</ModalHeader>
        <ModalBody>{children}</ModalBody>
      </Modal>
      {renderFooter}
    </div>
  );
};

export default ModalComponent;

ModalComponent.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string,
  titleText: PropTypes.string,
  submitText: PropTypes.string
};
