import React from "react";
import PropTypes from "prop-types";

import sortIncentives from "../../../functions/incentives/Sort/sortIncentives";
import IncentivePreferences from "../IncentivesPreferences/IncentivesPreferences";
import IncentiveCatalog from "../../../components/IncentiveCatalog/IncentiveCatalog";
import { FormatAsDollars, FormatCarName } from "./../../../utils/Helpers/Format";
import { FormattedMessage, useIntl } from 'react-intl';

const EVIncentives = ({ car, incentives, usedEv }) => {
    const intl = useIntl()
    if (!incentives) return null;

    incentives = usedEv ? incentives.filter(incentive => incentive.applicable_to_used_vehicles) : incentives

    const totalSavings = sortIncentives(incentives).total("eligible-rebates");
    return (

        <section className="container" id="EVIncentives">
            <div className="EVIncentives">
                <div className="row">
                    <div className="col-12">
                        {usedEv ?
                            <>
                                <p className="h2 mb-3 padded text-center">
                                    <FormattedMessage
                                        id="ev.incentives.title.default"
                                        defaultMessage="Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}"
                                        description="EV Incentives Title"
                                        values={{
                                            savings: <strong style={{ color: "rgb(33, 135, 57)" }}>{FormatAsDollars(totalSavings, intl.locale)}</strong>,
                                            lineBreak: <br />,
                                            car: FormatCarName(car)
                                        }}
                                    />
                                </p>
                            </> :
                            <>
                                <p className="h2 mb-3 padded text-center">
                                    <FormattedMessage
                                        id="ev.incentives.title"
                                        defaultMessage="Up to {savings} in tax credits and rebates are potentially available {lineBreak} for the {car}"
                                        description="EV Incentives Title"
                                        values={{
                                            savings: <strong style={{ color: "rgb(33, 135, 57)" }}>{FormatAsDollars(totalSavings, intl.locale)}</strong>,
                                            lineBreak: <br />,
                                            car: FormatCarName(car)
                                        }}
                                    />
                                </p>
                            </>
                        }

                        <div className="text-center" style={{ marginBottom: "31px" }}>
                            <IncentivePreferences
                                titleText="PERSONALIZE INCENTIVES"
                                btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "PERSONALIZE INCENTIVES" }) : "PERSONALIZE INCENTIVES"}
                            />
                        </div>
                        <IncentiveCatalog
                            incentives={incentives}
                            usedEv={usedEv}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default EVIncentives;

EVIncentives.propTypes = {
    car: PropTypes.object,
    incentives: PropTypes.array
};
