import React from "react";
import PropTypes from "prop-types";
import Uuid from "../../../utils/Uuid/Uuid"
import { useIntl } from "react-intl";

import "./ComparedVehicleToggle.scss";

const Buttons = ({
  comparedVehicles,
  onChangeVehicleNumber,
  selectedVehicleNumber
}) => {
  const intl = useIntl();
  return comparedVehicles.map((vehicle, i) => {
    let btnClasses = ["btn", "btn-default"];
    if (selectedVehicleNumber === i) btnClasses.push("active");
    return (  
      <button
        type="button"
        className={btnClasses.join(" ")}
        onClick={() => onChangeVehicleNumber(i)}
        key={Uuid()}
      >
        {(vehicle != null) ? vehicle.make : intl.formatMessage({ id: "vehicle", defaultMessage: "Vehicle" })}
        <br />
        <small>{
          (vehicle != null) ? `${vehicle.model} ${vehicle.trim}`.trim() : intl.formatMessage({ id: "vehicle.selectVehicle", defaultMessage:"Please Select A Vehicle" })
            }
        </small>
      </button>
    );
  });
};

const ComparedVehicleToggle = ({
  comparedVehicles,
  selectedVehicleNumber,
  onChangeVehicleNumber
}) => {
  return (
    <div className="ComparedVehicleToggle d-flex d-md-none d-lg-none btn-group">
      <Buttons
        comparedVehicles={comparedVehicles}
        selectedVehicleNumber={selectedVehicleNumber}
        onChangeVehicleNumber={onChangeVehicleNumber}
      />
    </div>
  );
};

ComparedVehicleToggle.propTypes = {
  comparedVehicles: PropTypes.array,
  onChangeVehicleNumber: PropTypes.func,
  selectedVehicleNumber: PropTypes.number
};

ComparedVehicleToggle.defaultProps = {
  comparedVehicles: []
};

export default ComparedVehicleToggle;
