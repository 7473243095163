const USER_PREF_PRESETS_CLIENT_CUSTOM = {

    milesDrivenDaily: 50,
    costPublicCharging: "0.3",
    vehicleClassTypeFilter: {
        ridesEats: true, 
        eatsOnly: false
    }, 
    min_seats: 4,
    vehicleFuelTypeFilters: {
        bev: true,
        phev: false,
    },
    additionalParams:""
  };

  export default USER_PREF_PRESETS_CLIENT_CUSTOM;
