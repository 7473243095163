import {
  AVERAGE_MSRP,
  INSURANCE_COST_SCALING_FACTOR,
  AVERAGE_INSURANCE_COST_ANNUALLY
} from "../../../../client_customizations/data/assumptions/ASSUMPTIONS"

import  { MUNICIPALITY_INFO } from "../../../../data/assumptions/MUNICIPALITY";

const InsuranceCost = {
  perYear: function(vehicle, municipality) {
    if (!vehicle) return 0;
    let insurance = process.env.REACT_APP_DYNAMIC_INSURANCE_COST && MUNICIPALITY_INFO[municipality] !== undefined ?
      MUNICIPALITY_INFO[municipality].insurance : AVERAGE_INSURANCE_COST_ANNUALLY.value

    return (
      Number(insurance) *
      (1 +
        INSURANCE_COST_SCALING_FACTOR.value *
          (vehicle.msrp / AVERAGE_MSRP.value - 1))
    );
  },

  perMile: function(vehicle, milesDrivenAnnually, municipality) {
    if (!vehicle) return 0;

    let cost = this.perYear(vehicle, municipality) / milesDrivenAnnually;

    return isFinite(cost) ? cost : 0;
  }
};

export default InsuranceCost;
