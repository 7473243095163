import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";

const ZipcodeErrorState = ({
  zipcode,
  workingZipcode,
  isSynced,
  isUpdating,
  isValid,
  isNotFound,
  hideValidateRequest
}) => {
  if (isUpdating) return null;

  if (!isValid) {
    return (
      <div className="text-danger small">
          {window.location.pathname.toLocaleLowerCase().includes("/ca") ? 
          <FormattedMessage 
            id="chargingMap.errorPostcode"
            defaultMessage="Error: {workingZipcode} is not a valid Postcode"
            description="Postcode Error"
            values= {{
              workingZipcode: workingZipcode
            }}
          />
          :
          <FormattedMessage 
            id="chargingMap.errorZipcode"
            defaultMessage="Error: {workingZipcode} is not a valid zipcode"
            description="Zipcode Error"
            values= {{
              workingZipcode: workingZipcode
            }}
          />
          }
      </div>
    );
  }

  if (!isSynced && !hideValidateRequest) {
    return (
      <div className="text-muted small">
        Press enter to validate ZIP code [{workingZipcode}] with the server.
      </div>
    );
  }

  if (isSynced && isNotFound) {
    return (
      <div className="text-danger small">
        Error: The ZIP Code {zipcode} is not part of {process.env.REACT_APP_FULL_COMPANY_NAME}&#39;s territory.
      </div>
    );
  }

  return null;
};

export default ZipcodeErrorState;

ZipcodeErrorState.propTypes = {
  zipcode: PropTypes.string,
  workingZipcode: PropTypes.string,
  isSynced: PropTypes.bool,
  isUpdating: PropTypes.bool,
  isValid: PropTypes.bool,
  isNotFound: PropTypes.bool,
  hideValidateRequest: PropTypes.bool
};