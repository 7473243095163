import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { FormattedMessage, useIntl } from 'react-intl';
import ButtonGroup from "../../shared/InputElements/ButtonGroup";
import Select from "../../shared/InputElements/Select";
import ToolTip from "../../../components/shared/ToolTip/ToolTip";


const ChooseTaxFilingStatus = ({
  id = "tax-filing-status",
  ...rest
}) => {
  const userPrefs = useContext(UserPrefsContext);
  const intl = useIntl()
  const label = intl.formatMessage({ id: "incentives.taxFilingStatus", defaultMessage: "Tax Filing Status"})


  return (
    <>
      <div className="d-none d-md-block">
      <span className="h3">
                    <FormattedMessage
                        id="incentives.income"
                        defaultMessage="Income and tax status"
                        description="Income and tax status"
                    />
                    <ToolTip
                        message={intl.formatMessage({ id: "incentives.incomeTooltip", defaultMessage: "Incentive eligibility and the amount you can receive often depends on your income. Lower  income customers are typically eligible for higher rebates." })}
                        id="income_and_tax_status_label_tooltip"
                    />
                </span>
        <ButtonGroup
          id={id}
          value={userPrefs.get("taxFilingStatus")}
          label={label}
          optionNames={[intl.formatMessage({ id: "incentives.single", defaultMessage: "Single"}), intl.formatMessage({ id: "incentives.married", defaultMessage: "Married"}), intl.formatMessage({ id: "incentives.headOfHousehold", defaultMessage: "Head of Household"})]}
          optionValues={["single", "joint", "head_of_household"]}
          handler={(e, selected) =>
            userPrefs.set({ taxFilingStatus: selected })
          }
          {...rest}
        />
      </div>
      <div className="d-block d-md-none">
        <Select
          id={id}
          value={userPrefs.get("taxFilingStatus")}
          label={label}
          optionNames={[intl.formatMessage({ id: "incentives.single", defaultMessage: "Single"}), intl.formatMessage({ id: "incentives.married", defaultMessage: "Married"}), intl.formatMessage({ id: "incentives.headOfHousehold", defaultMessage: "Head of Household"})]}
          optionValues={["single", "joint", "head_of_household"]}
          handler={e => userPrefs.set({ taxFilingStatus: e.target.value })}
          {...rest}
        />
      </div>
    </>
  );
};

export default ChooseTaxFilingStatus;

ChooseTaxFilingStatus.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string
};