import React, { useState } from "react";
import PropTypes from "prop-types";
import { useIntl } from 'react-intl';
import { FormattedMessage } from "react-intl";
import downArrow from "../../../client_customizations/assets/images/down_arrow.svg"

const ShowHideButtonSpecial = ({
    children,
    buttonText,
    displayChildrenOnlyOnTablet,
    displayChildrenOnlyOnMobile,
    displayAlertOnlyOnMobile
}) => {
    const [showChildren, setShowChildren] = useState(false);

    const toggleButton = () => {
        setShowChildren(!showChildren);
    };
    const intl = useIntl()


    let renderChildren = showChildren ? children : null;
    let renderButtonText = showChildren
        ? intl.formatMessage ? intl.formatMessage({ id: "graph.hide", defaultMessage: "Hide" }) + buttonText : "Hide" + buttonText
        : intl.formatMessage ? intl.formatMessage({ id: "graph.show", defaultMessage: "Show" }) + buttonText : "Show" + buttonText;

    let button = (
        <div className="show-sources-dropdown" onClick={toggleButton} >
            <img alt="down-arrow" className="jumbotron-dropdown-button" src={downArrow}></img>
            <button
                type="button"
                className="jumbotron-dropdown-button arrow"
                style={{
                    textAlign: "justify",
                    fontWeight: "400",
                    fontSize: "0.85rem"
                }}
            >
                {renderButtonText}
            </button>
        </div>
    );

    let desktopClasses = "show-full-list-container d-none d-md-block";
    let mobileClasses = "d-block d-md-none";
    if (displayChildrenOnlyOnTablet) {
        desktopClasses = "show-full-list-container d-none d-lg-block";
        mobileClasses = "d-block d-lg-none";
    }

    return displayChildrenOnlyOnTablet ||
        displayChildrenOnlyOnMobile ||
        displayAlertOnlyOnMobile ? (
        <>
            <div className={desktopClasses}>
                {button}
                {renderChildren}
            </div>
            <div className={mobileClasses}>
                {displayChildrenOnlyOnMobile && children}
                {displayChildrenOnlyOnTablet && children}
                {displayAlertOnlyOnMobile && (
                    <div className="input-well">
                        <p>
                            <FormattedMessage
                                id="ev.carDetails.mobile"
                                defaultMessage="For more details including charts {amp} calculations, please view this page on a desktop."
                                description="Car Details Mobile subtitle"
                                values={{
                                    amp: "&",
                                }}
                            />
                        </p>
                    </div>
                )}
            </div>
        </>
    ) : (
        <div className="show-full-list-container">
            {button}
            {renderChildren}
        </div>
    );
};

export default ShowHideButtonSpecial;

ShowHideButtonSpecial.propTypes = {
    children: PropTypes.node.isRequired,
    buttonText: PropTypes.string,
    displayChildrenOnlyOnTablet: PropTypes.bool,
    displayChildrenOnlyOnMobile: PropTypes.bool,
    displayAlertOnlyOnMobile: PropTypes.bool
};
