import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import { FormatAsDollars } from "../../../../utils/Helpers/Format";

import Range from "../../../../components/shared/InputElements/Range"
import {useIntl} from 'react-intl';



const SlideMaxBudget = ({
  id = "max-budget-range",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = intl.formatMessage ? intl.formatMessage({ id: "evs.budgetAfterIncentives", defaultMessage: "Budget after Incentives"}) : "Budget after Incentives"
  const description = val => {
    return FormatAsDollars(val, intl.locale);
  };

  return (
    <Range
      id={id}
      value={userPrefs.get("maxBudget")}
      label={label}
      rangeMin={15000}
      rangeMax={150000}
      rangeStep={500}
      description={description}
      ariaControls="electric-vehicles-catalog"
      handler={e => userPrefs.set({ maxBudget: e.target.value })}
      hasSupportingInfoBelow
      {...rest}
    />
  );
};

export default SlideMaxBudget;

SlideMaxBudget.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
