import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import { Link } from "react-router-dom";
import "./EVCard.scss";
import { renderVehicleClassBadge, renderFuelTypeBadge } from '../../functions/vehicle/RenderBadges/RenderBadges'
import RenderItem from "../../../components/RenderItem/RenderItem";
import VehicleImage from "../../../components/VehicleImage/VehicleImage";
import calcMatchScore from "../../../functions/vehicle/MatchScore/calcMatchScore";
import getPaymentDetails from "../../../functions/vehicle/getPaymentDetails";
import { FormatAsDollars, FormatAsThousands, FormatCarModelAndTrim } from "./../../../utils/Helpers/Format";
import IconLocal from "../../assets/images/icons/icon-local.png";
import isPHEV from "../../../functions/vehicle/isPHEV";
import { FormattedMessage, useIntl } from 'react-intl';

const EVCard = ({
    ev,
    hasEvDetails,
    hasIncentivesAndMatchScore,
    hasLinkToEv,
    hasLocallyAvailableHidden
}) => {
    const intl = useIntl()
    const userPrefs = useContext(UserPrefsContext);
    let size = window.innerWidth > 600;

    if (!ev) return null;

    let evImgs = ev.images ? ev.images.map((n) =>
        (n.url_thumbnail == null) ? [] :
            (n.url_full == null) ? [] :
                [n]) : [];

    //Flatten evImgs into a single array                  
    const evImgSrc = [].concat.apply([], evImgs);
    const imgSrc = ev.images ? (ev.images.length >= 0 ? evImgSrc[0] : "") : "";
    const msrp = ev.msrp ? ev.msrp : 0;
    const paymentDetails = getPaymentDetails(
        ev,
        userPrefs
    );
    const afterIncentives = paymentDetails.afterIncentives
        ? paymentDetails.afterIncentives
        : 0;

    const renderLocalIcon = ev.locally_available ? (
        <span className="badge-locally-available">
            <img alt="Available Locally" src={IconLocal} />
        </span>
    ) : null;

    let altText = "Go to " + (ev.make + " " + ev.model + " " + ev.trim).toString().trim();


    const renderEVDetails = hasEvDetails ? (
        <div className="renderRowOfData">
            {!isPHEV(ev) && (
                <p>
                    <small>&nbsp;</small>
                    <span />
                </p>
            )}
            <p>
                <small>
                    <FormattedMessage
                        id="evCard.electricRange"
                        defaultMessage="Electric Range"
                        description="Electric Range"
                    />
                </small>
                <span>{ev.electric_range} {userPrefs.get("country") === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometersShort", defaultMessage: "km" }) : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" })}</span>
            </p>
            {isPHEV(ev) && (
                <p>
                    <small>
                        <FormattedMessage
                            id={size ? "evCard.totalRange": "evCard.totalRange.mobile"}
                            defaultMessage="Total Range"
                            description="Total Range"
                        />
                    </small>
                    <span>{ev.total_range} {userPrefs.get("country") === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "kilometersShort", defaultMessage: "km" }) : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" })}</span>
                </p>
            )}
            <p>
                <small>
                    <FormattedMessage
                        id="vehicle.msrp"
                        defaultMessage="MSRP"
                        description="MSRP"
                    />
                </small>
                <span>{FormatAsDollars(msrp, intl.locale)}</span>
            </p>
        </div>
    ) : null;

    const renderCardBottom = hasIncentivesAndMatchScore ? (
        <div className="EVCardBottom">
            <div>
                <RenderItem
                    title={intl.formatMessage({ id: "vehicle.afterIncentives", defaultMessage: "AFTER INCENTIVES" })}
                    value={
                        intl.locale === "FR" 
                        ? 
                    FormatAsDollars(afterIncentives, intl.locale) 
                        : 
                        <>
                            <span className="monetarySymbol">
                                {intl.formatMessage({ id: "currency", defaultMessage: "$" })}
                            </span>
                            {FormatAsThousands(afterIncentives)}
                        </>
                        }
                />
            </div>
            <div>
                <RenderItem
                    title={intl.formatMessage({ id: "evCard.matchScore", defaultMessage: "MATCH SCORE" })}
                    value={calcMatchScore(
                        ev,
                        userPrefs
                    )}
                    matchScore
                />
            </div>
        </div>
    ) : (
        hasLinkToEv && (
            <div className="EVCardBottomWithLink">
                <Link
                    to={`${userPrefs.get("additionalParams")}/vehicles/${ev.handle}`}
                    className="btn btn-ae"
                    target="_blank"
                    style={{ outline: "none" }}
                >
                    <FormattedMessage
                        id="evCard.seeElectricVehicle"
                        defaultMessage="SEE ELECTRIC VEHICLE"
                        description="SEE ELECTRIC VEHICLE"
                    />
                </Link>
            </div>
        )
    );

    return (
        <>
            <div className="EVCardTop">
                {!hasLocallyAvailableHidden && renderLocalIcon}
                <div className="badge-fuel-type">
                    {renderVehicleClassBadge(ev)}
                    {renderFuelTypeBadge(ev)}
                </div>
                <p className="h2" style={{ maxWidth: "70%" }}>
                    {ev.make}
                </p>
                <p className="modal-title mt-1">
                    {FormatCarModelAndTrim(ev)}
                </p>
                <div className="text-center">
                    <VehicleImage image={imgSrc} size="thumb" alt={altText} />
                </div>
                {renderEVDetails}
            </div>
            {renderCardBottom}
        </>
    );
};

export default EVCard;

EVCard.propTypes = {
    ev: PropTypes.object,
    hasEvDetails: PropTypes.bool,
    hasIncentivesAndMatchScore: PropTypes.bool,
    hasLinkToEv: PropTypes.bool,
    hasLocallyAvailableHidden: PropTypes.bool
};
