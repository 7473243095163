import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";

import CarGallery from "../../../components/EVJumbotron/CarGallery/CarGallery";
import CarDetails from "../../../components/EVJumbotron/CarDetails/CarDetails";
import CarDetailsCalcs from "../../../components/EVJumbotron/CarDetailsCalcs/CarDetailsCalcs";

import CaretDown from "../../assets/icons/caretDown.png";

import VehicleImage from "../../../components/VehicleImage/VehicleImage";
import PricePanels from "./PricePanels/PricePanels";
import { FormatCarName } from "../../../utils/Helpers/Format";
import ShowHideButton from "../../../components/ShowHideButton/ShowHideButton";
import { FormattedMessage } from 'react-intl';
import { useIntl } from 'react-intl';
import UsedVehiclePanels from "../EVJumboTronDropdown/UsedVehiclesPanels/UsedVehiclePanels";



const EVJumbotronDropdown = ({ car, paymentDetails, cars, setUsedEv }) => {
    const intl = useIntl()
    const userPrefs = useContext(UserPrefsContext);

    if (!car) return null;
    
    //Error Checking code to ensure that pictures have both a thumbnail and full version sutible for display
    // It will remove any null value 
    let imagesSrc = car && car["images"] ? car.images.map((n) =>
        (n.url_thumbnail == null) ? [] :
            (n.url_full == null) ? [] :
                [n]) : [];

    //Flatten evImgs into a single array                  
    const images = [].concat.apply([], imagesSrc);
    let carImages = images.map(image => {
        return {
            original: image.url_full,
            thumbnail: image.url_thumbnail,
            originalAlt: image["legal_info"],
            thumbnailAlt: image.title
        };
    });
    carImages.shift();

    function Dropdown() {
        let ele = document.getElementById("ev-jumbotron-details")
        if (ele.style.display === "block") {
            ele.style.display = "none";
        } else {
            ele.style.display = "block";
            ele.style.backgroundColor = "white";
            ele.style.borderBottom = "1px solid #E8E8E8"
        }
    }

    return (
        <>
            <section className="container" id="ev-jumbotron-title">
                <div className="row">
                    <div className="col">
                        <h1>
                            {setUsedEv ?
                                <FormattedMessage
                                    id="ev.usedCarTitle"
                                    defaultMessage="Used {car}"
                                    description="Used Car Title"
                                    values={{
                                        car: FormatCarName(car)
                                    }}
                                />
                                :
                                FormatCarName(car)
                            }
                        </h1>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12 col-md-7 col-lg-8">
                        <div className="text-center offset-lg-1 col-lg-10 col-md-12">
                            <VehicleImage image={images[0]} size="full" alt={FormatCarName(car)} />
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-lg-4">
                        <PricePanels car={car} paymentDetails={paymentDetails} />
                        <UsedVehiclePanels cars={cars} car={car} setUsedEv={setUsedEv} />
                        <br />
                        {setUsedEv ? null :
                            <>
                                <div className="row">
                                    <div className="col">
                                        <Link to={`${userPrefs.get("additionalParams")}/vehicles`}>
                                            <button style={{ whiteSpace: "nowrap" }} className="white-btn-ae">
                                                <FormattedMessage
                                                    id="ev.jumbotron.viewAllCars"
                                                    defaultMessage="VIEW ALL CARS"
                                                    description="VIEW ALL CARS"
                                                />
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </>
                        }
                        {/* {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? 
              <div className="col">
                <Link to="/compare-vehicles">
                  <button className="btn-ae">Compare</button>
                </Link>
              </div>
              : null} */}
                    </div>
                </div>
            </section>
            <div className="jumbotron-dropdown" onClick={Dropdown}>
                <img className="caretImg" alt="down-arrow" src={CaretDown}></img>
                <button className="jumbotron-dropdown-button" >{intl.formatMessage({ id: "ev.carDetails.view", defaultMessage: "View vehicle details" })}</button>
            </div>
            <section style={{display: "none"}} className="container" id="ev-jumbotron-details" >
                
                <div className="row">
                    <div className="col-md-7">
                        <CarDetails car={car} setUsedEv={setUsedEv} />
                        <br />
                        <div className="text-center">
                            <ShowHideButton
                                buttonText={intl.formatMessage({ id: "detailedCalculations", defaultMessage: "DETAILED CALCULATIONS" })}
                                displayAlertOnlyOnMobile
                            >
                                <div className="input-well">
                                    <CarDetailsCalcs car={car} />
                                </div>
                            </ShowHideButton>
                        </div>
                    </div>
                    <div className="col-md-5">
                        <CarGallery carImages={carImages} />
                    </div>
                </div>
                <br />
            </section>
        </>
    );
};

export default EVJumbotronDropdown;

EVJumbotronDropdown.propTypes = {
    car: PropTypes.object,
    paymentDetails: PropTypes.object
};
