import React, { useContext } from "react";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import { Link } from "react-router-dom";
import "./Footer.scss";
import { FormattedMessage } from 'react-intl';

import logo from "../../../assets/images/logo-footer.svg";
import DisclaimerComponent from "@zappy-ride/disclaimer-footer-component";

const Footer = ({ language, changeLanguage, path }) => {
  const userPrefs = useContext(UserPrefsContext);

  let caWebsite = path ? path.substring(0, 3) === "/ca" : false

  return (
    <footer className="Footer">
      <div className="container pt-4 pb-4">
        <div className="row nav-links">
          <img
            src={logo}
            alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
            className="img-fluid footer-title"
          />
          {process.env.REACT_APP_PAGES_VEHICLES_ENABLED ? (<>
            <Link to={`${userPrefs.get("additionalParams")}/vehicles`}>
              <FormattedMessage
                id="electricVehicles"
                defaultMessage="Electric Vehicles"
                description="Electric Vehicles Footer"
                values={{
                  electricVehicles: process.env.REACT_APP_PAGES_VEHICLES_TITLE
                }}
              />
            </Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (<>
            <Link to={`${userPrefs.get("additionalParams")}/used-vehicles`}>
              <FormattedMessage
                id="usedElectricVehicles"
                defaultMessage="Used Vehicles"
                description="Used Vehicles Footer"
                values={{
                  electricVehicles: process.env.REACT_APP_PAGES_USED_VEHICLES_TITLE
                }}
              />
            </Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_COMPARE_ENABLED ? (<>
            <Link to={`${userPrefs.get("additionalParams")}/compare-vehicles`}>
              <FormattedMessage
                id="compareVehicles"
                defaultMessage="Compare Vehicles"
                description="Compare Vehicles Footer"
                values={{
                  compareVehicles: process.env.REACT_APP_PAGES_COMPARE_TITLE
                }}
              />
            </Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED ? (<>
            <Link to={`${userPrefs.get("additionalParams")}/incentives`}>
              <FormattedMessage
                id="incentives"
                defaultMessage="Incentives"
                description="Incentives Footer"
                values={{
                  incentives: process.env.REACT_APP_PAGES_INCENTIVES_TITLE
                }}
              />
            </Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (<>
            <Link to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}>
              {process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (<>
            <Link to="/faq">{process.env.REACT_APP_PAGES_FAQ_TITLE}</Link>
          </>) : (null)}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (<>
            <Link to="/home-chargers" className="">
              <FormattedMessage
                id="homeChargers"
                defaultMessage="Home Chargers"
                description="Home Chargers Footer"
                values={{
                  homeChargers: process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE
                }}
              />
            </Link>
          </>) : (null)}
          <div className="support-links">
            <a target="_blank" rel="noopener noreferrer" href={caWebsite ? language === "FR" ? "https://www.uber.com/legal/en/document/?name=privacy-notice&country=canada&lang=fr-ca" : "https://www.uber.com/legal/en/document/?country=canada&lang=en&name=privacy-notice" : "https://www.uber.com/legal/en/document/?country=united-states&lang=en&name=privacy-notice"}>
              <FormattedMessage
                id="footer.privacy"
                defaultMessage="Privacy"
                description="Privacy Option"
              />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={caWebsite ? language === "FR" ? "https://www.uber.com/ca/fr-ca/about/accessibility/" : "https://www.uber.com/ca/en/about/accessibility/" : "https://www.uber.com/us/en/about/accessibility/"}>
              <FormattedMessage
                id="footer.accessibility"
                defaultMessage="Accessibility"
                description="Accessibility Option"
              />
            </a>
            <a target="_blank" rel="noopener noreferrer" href={caWebsite ? language === "FR" ? "https://www.uber.com/legal/en/document/?name=general-terms-of-use&country=canada&lang=fr-ca" : "https://www.uber.com/legal/en/document/?name=general-terms-of-use&country=canada&lang=en" : "https://www.uber.com/legal/en/document/?name=general-terms-of-use&country=united-states&lang=en"}>
              <FormattedMessage
                id="footer.terms"
                defaultMessage="Terms"
                description="Terms Option"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="container-fluid" style={{ backgroundColor: "black" }}>
        <div className="container" style={{ padding: "0px 0px 10px 30px" }}>
          <p  style={{ marginLeft: "10px", color: "#989898", fontSize: "11px" }}>
            <span style={{ fontFamily: "Arial, Helvetica" }}>&copy;</span>
            {(new Date().getFullYear().toString())} {caWebsite ? process.env.REACT_APP_FULL_COMPANY_NAME_CA : process.env.REACT_APP_COMPANY_NAME}.
          </p>
          {/* <p style={{ color: "#989898", fontSize: "11px" }}>
            <span style={{ fontFamily: "Arial, Helvetica" }}> &copy;
              {(new Date().getFullYear().toString()) + " "} ZappyRide (c). {" "}
              <a style={{ fontFamily: "Arial, Helvetica", color: "#989898", fontSize: "11px" }} rel="noopener noreferrer" target="_blank" href="https://zappyride.com/legal-notices">Legal notices.</a>
            </span>
          </p> */}
          <div className="disclaimer_container">
          <DisclaimerComponent clientName="zappynobackground"/>
          </div>

          {/* <p style={{ color: "#989898", fontSize: "11px" }}>
            <span style={{ fontFamily: "Arial, Helvetica" }}>
              Powered by ZappyRide (c).
            </span>
          </p>
          <p>
            <FormattedMessage
              id="footer.generalDisclaimer"
              defaultMessage="Vehicles displayed may not reflect actual availability. Uber does not endorse or recommend any specific vehicle or car manufacturer.
              Uber is not responsible for the products or services offered by third -parties, including this cost-savings calculator, and makes no claims related to the effectiveness of such or to potential savings."
              description="General Disclaimer Footer"
            />
          </p> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
