import React from "react";
import PropTypes from "prop-types";

import IncentivePreferences from "../IncentivesPreferences/IncentivesPreferences";

import SlideMilesDrivenDaily from "../InputComponents/SlideMilesDrivenDaily/SlideMilesDrivenDaily";
import SlideMaxBudget from "../InputComponents/SlideMaxBudget/SlideMaxBudget";
import SelectChargerLevel from "../InputComponents/SelectChargerLevel/SelectChargerLevel";

import { FormattedMessage, useIntl } from 'react-intl';
import SlideGasolinePrice from "../../../components/InputComponents/SlideGasolinePrice/SlideGasolinePrice";

const MatchScoreOptions = ({ electricVehicles }) => {
    const intl = useIntl()

    return (
        <div className="input-well MatchScoreOptions">
            <p className="h3">
                <FormattedMessage
                    id="evs.matchScoreOptions"
                    defaultMessage="Refine Match Score"
                    description="Refine Match Score"
                />
            </p>
            <SlideMilesDrivenDaily />

            <SlideMaxBudget />
            <IncentivePreferences
                electricVehicles={electricVehicles}
                titleText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives" }) : "Personalize Incentives"}
                btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives" }) : "Personalize Incentives"}
                btnSubtle
            />

            {/* <div style={{ height: "1rem" }} /> */}

            {/* <SlideMinSeats /> */}
            <SelectChargerLevel />

                    
            <div style={{ height: "1rem" }} />

            <SlideGasolinePrice />
        </div>
    );
};

export default MatchScoreOptions;

MatchScoreOptions.propTypes = {
    electricVehicles: PropTypes.array
};
