const FR_CUSTOM = {
    "ev.carDetails.calc.emissions": "Émissions",
    "ev.carDetails.calc.charging": "Recharge",
    "ev.carDetails.calc.fuel": "Carburant",
    "graph.show": "Voir",
    "graph.hide": "Cacher",
    "vehicle.milesPerThirtyFastCharge": "Par 30 minutes de charge rapide",
    "ev.milesDrivenAnnually": "Kilomètres parcourus par an",
    "vehicle.dailyMiles": "Kilomètres par an",
    "graph.speedOfCharge.levelTwoChargingSpeed": "Vitesse du chargeur de niveau 2",
    "ev.carDetails.levelTwoChargerSpeedTooltip": "Vitesse du chargeur de niveau 2",
    "graph.speedOfCharge.fastChargingSpeed": "Vitesse de charge rapide",
    "graph.speedOfCharge.fastChargerTitle": "Quantité de la charge pour 30 minutes de charge rapide",
    "graph.speedOfCharge.mphLevelTwoChargerTitle": "Quantité de la charge par heure de charge de niveau 2",
    "graph.electricRange.assumption": "Gamme basée sur les estimations de l'EPA",
    "graph.assumptions": "Hypothèses",
    "ev.carDetails.calc.kwPerMile" : "kWh par 100 km",
    "ev.cardetails.calc.litersUsed": "Litres utilisés",
    "ev.cardetails.calc.litersUsedFormula": "Kilomètres parcourus * (1 - Portion de la conduite électrique) * L / 100 km",
    "ev.cardetails.calc.litersSaved": "Litres économisés",
    "ev.cardetails.calc.litersSavedFormula": "Litres utilisés par un véhicule à essence équivalent - Litres utilisés par VE",
    "ev.cardetails.calc.emissionsPerTree": "Émissions économisées par arbre",
    "ev.cardetails.calc.emissionsReudctions": "Réductions d'émissions",
    "ev.cardetails.calc.portionElectricSource": "Entrée utilisateur. Pour un véhicule hybride, estime la portion de kilomètres parcourus avec la batterie électrique au lieu de l'essence.",
    "ev.incentives.title": "Jusqu'à {savings} de crédits d'impôt et de remises sont potentiellement disponibles pour la {car}",
    "evs.homeChargingAvailabilityTooltip": "Plus de véhicules électriques vous conviendront si vous pouvez recharger rapidement à la maison.",


    "vehicle.zipcode": "Code postal",
    "vehicle.allElectric": "Tout-électrique",
    "detailedCalculations": "Détails",
    "vehicle.rides": "Uber Rides & Eats",
    "vehicle.eats": "Uber Eats seulement",
    "vehicle.class": "Classe de véhicule",
    "vehicle.estimatedIncentives": "Total des subventions",
    "vehicle.make": "Marque",
    "vehicle.model": "Modèle",
    "vehicle.batterySize": "Taille estimée de la batterie",
    "header.newVehicles": "Découvrez de nouveaux véhicules",
    "header.usedVehicles": "Découvrez les véhicules d'occasion",
    "footer.generalDisclaimer": "Les véhicules affichés peuvent ne pas refléter la disponibilité réelle. Uber n'approuve ni ne recommande aucun véhicule ou fabricant. Uber n'est pas responsable des produits ou services offerts par des tiers, y compris ce calculateur, et ne fait pas de promesse liée à ces économies ou à des économies potentielles.",
    "homepage.title": "Votre guide des coûts de la voiture électrique",
    "homepage.subTitle": "Voitures électriques populaires aujourd'hui",
    "homepage.compare": "Comparez le coût du véhicule de votre choix à celui d’un véhicule à essence similaire. Un VÉ peut vous permettre d’économiser de l’argent, du fait qu’il a un coût d’entretien moins élevé qu’un véhicule qui fonctionne entièrement à l’essence.",
    "homepage.linkCard.browseElectricVehicles": "Catalogue de nouveaux véhicules électriques",
    "homepage.linkCard.browseUsedVehicles": "Catalogue des véhicules électriques d'occasion",
    "homepage.linkCard.compareVehicles": "Comparer les véhicules neufs",
    "homepage.linkCard.incentives": "Voir les subventions disponibles",
    "evs.welcomeSub": "Parlez-nous un peu de votre style de conduite et de vos préférences afin que nous {lineBreak} puissions vous montrer les VEs qui conviennent le mieux à votre style de vie.",
    "evs.roundTripCommute": "Kilomètres par jour",
    "evs.homeChargingAvailability": "Charge à domicile disponible?",
    "evCard.totalRange": "Autonomie totale estimée",
    "evCard.totalRange.mobile": "Est. autonomie totale",
    "used.evCard.electricRangeAvg.short": "Autonomie électrique moyenne",
    "evSort.title": "Filtrer par",
    "ev.jumbotron.viewAllCars": "Voir toutes les voitures",
    "assumption.Pricegasoline": "Prix ​​de l'essence ($ / L)",
    "vehicle.gasoline.price": "Prix ​​de l'essence ($ / L)",
    "ev.incentives.title.default": "Subventions et remises disponibles",
    "incentives.updating": "Mise à jour ...",
    "results.updated": "Les résultats ont été mis à jour le",
    "compareVehicles.subTitle": "Voir tous les véhicules",
    "compareVehicles.pickTwo": "Choisissez au moins 2 véhicules pour commencer la comparaison.",
    "incentives.subTitle": "Vous pourriez être éligible pour des subventions, y compris des rabais, des crédits d'impôt et divers autres avantages. Sélectionnez Personnaliser les subventions à en savoir plus sur lesquelles vous êtes disponible.",
    "incentives.title": "Subventions pour véhicules électriques",
    "ev.used.yearRange": "Années",
    "used.evCard.yearRange": "Années",
    "ev.used.electricRange": "Autonomie électrique moyenne",
    "used.evCard.electricRangeAvg": "Autonomie électrique moyenne",
    "footer.privacy": "Politique de confidentialité",
    "footer.accessibility": "Accessibilité",
    "footer.terms": "Mentions légales",
    "usedElectricVehicles": "Véhicules d'occasion",
    "graph.costOfOwnership.buttonMonthly": "Mensuel",
    "graph.costOfOwnership.buttonAnually": "Annuel",
    "graph.costOfOwnership.buttonOverall": "Globalement",
    "ev.carDetails.view": "Voir les détails du véhicule",
    "ev.compareOtherVehicles": "Comparer les autres véhicules",
    "ev.lease": "Comptant",
    "ev.carDetails.calc.DCFC": "Taux de charge rapide DC",
    "incentives.income": "Statut pour l'impôt sur le revenu",
    "incentives.single": "Seul",
    "incentives.married": "Marié",
    "incentives.headOfHousehold": "Chef du ménage",
    "incentives.taxFilingStatus": "Statut de dépôt des impôts",
    "incentives.householdSize": "Taille du ménage",
    "incentives.householdIncome": "Revenu du ménage",
    "incentives.incomeTooltip": "L'éligibilité et le montant de la subvention que vous pouvez recevoir dépendent souvent de votre revenu. Les personnes à faible revenu sont généralement éligibles pour des rabais plus élevés.",
    "feedback.title": "Retour d'information",
    "feedback.goal": "Quel est l'objectif de votre visite?",
    "feedback.interest": "Êtes-vous intéressé par l'achat d'un véhicule électrique?",
    "feedback.batteryElectric": "Batterie électrique",
    "feedback.pluginHybrid": "Hybride rechargeable",
    "feedback.notInterested": "Pas intéressé",
    "feedback.improve": "Comment amélioreriez-vous cet outil?", 
    "feedback.help": "Que peut faire Uber faire pour vous aider à obtenir un véhicule électrique?",
    "feedback.submit": "Envoyer",
    "ev.costOfPublicCharing": "Est. Coût de la recharge avec borne publique",
    "ev.publicPrivateCharging": "Mélanger la recharge publique / privée",
    "ev.publicCharging.low": "Bas",
    "ev.publicCharging.medium": "Moyen",
    "ev.publicCharging.high": "Haut",
    "ev.publicCharging.free": "Gratuit",
    "ev.showSources.totalGasolineCostSource": "Coût de l'essence * kilomètres à vie * {portionConduitElectrique} / kpl",
    "vehicles.none": "Aucun véhicule trouvé",
    "vehicles.new": "Nouvelle",
    "vehicles.used": "D'occasion",
    "vehicle.age.one": "0-1 Années",
    "vehicle.age.two": "2-3 Années",
    "vehicle.age.four": "4+ Années",
    "ev.public": "publique",

    "vehicle.fuelType": "Type du carburant",
    "vehicle.afterIncentives": "Après subventions",
    "vehicle.timeToChargeLvlTwo": "Temps de recharge - niveau 2",
    "vehicle.kilometersPerThirtyFastCharge": "Kilomètres par 30 min de charge rapide",
    "vehicle.coTwoEmissions": "Réduction des éeissions de C02",
    "vehicle.electricRange": "Autonomie électrique",
    "evCard.matchScore": "Score de compatibilité",
    "vehicle.type": "Type",

    "header.compareVehicles": "Comparer les véhicules neufs",
    "loading": "Chargement en cours",
    "vehicle.kilometersCapital": "Kilomètres",
    "currency": "$",
    "kilometersShort": "km",
    "vehicle.kilometersShort": "km",
    "kgperyear": "kg/an",
    "literperyear": "L/an",
    "graph.electricRange.description": "Description",
    "gasoline": "l'essence",
    "ev.usedCarTitle": "{car} d'occasion",
    


    "ev.cardetails.calc.kmDrivenPerYr": "Kilomètres parcourus par an",
    "ev.cardetails.calc.milesDrivenPerYr" : "Kilomètres parcourus par an",
    "ev.cardetails.calc.electricityEmissions": "Émissions électriques en Kg / MWh",
    "trees" : " arbres",
    "ev.cardetails.calc.emissionsReductions": "Réductions d'émissions",
    "ev.cardetails.calc.arborDayFoundation": "Fondation Arbor Day",
    "ev.electricityRateCalc": "Certaines juridictions canadiennes offrent actuellement un allégement COVID des tarifs d'électricité. Les tarifs et prix publiés sur ce site Web sont basés sur des sources accessibles au public et adaptés à votre code postal. Veuillez consulter votre fournisseur d'électricité local pour confirmer les prix actuels dans votre région.",
    "ev.showSources.estimatedCostOfPublicCharging": "Estimé coût de la recharge avec borne publique",
    "ev.showSources.mixPublicPrivateCharging": "Mélanger la recharge publique / privée",
    "ev.showSources.dollarPerKilometerDriven": "$/100 parcours",
    "ev.showSources.totalKilometerElectricityCostSource": "$/100 parcours * Kilomètres * {portionEVDriving}/ 100",
    "ev.showSources.lifetimeKilometers": "Kilométrage à Vie",
    "ev.showSources.vehiclelPerKm": "LPerKm du véhicule (Litres au kilomètre)",
    "ev.showSources.totalKilometerGasolineCostSource": "Coût de l'essence * Kilomètres * {portionEVDriving}/ lPerKm",
    "ev.showSources.maintenancePerKilometer": "Entretien au kilomètre pour voitures à essence",
    "ev.showSources.maintenancePerKilometerElectricitySource": "Entretien par kilomètre pour voiture à essence * Facteur de réduction de l'entretien des véhicules électriques",
    "ev.showSources.maintenancePerKilometerElectricity": "Entretien par kilomètre pour voiture électrique",
    "ev.showSources.blendedMaintenanceCostPerKilometer": "Coût d'entretien combiné par kilomètre",
    "ev.showSources.blendedMaintenanceCostPerKilometerSource": "Part de la conduite électrique appliquée à l'entretien par kilomètre pour l'essence et l'électricité",
    "ev.showSources.totalKilometerMaintenanceCostSource": "Coût d'entretien par kilomètre * Kilomètres",
    "ev.showSources.insuranceSource"  : "Extrapolation du coût d'assurance moyen en  ",
    "ev.showSources.insurancePerYearSource": "Extrapolation du coût d'assurance moyen en {state}, basé sur le PDSF du véhicule par rapport à un PDSF moyen de 37 000 $",
    "ev.showSources.dollarPerKilometerDrivenPublicCharging": "(Estimé coût de la recharge avec borne publique * Mélanger la recharge publique / privée + Prix de l'électricité * (1 - Mélanger la recharge publique / privée)) * Vehicle kWh per 100 km",
    "vehicle.gasoline.metricPrice": "Prix ​​de l'essence ($ / L)",
    "graph.electricRange.assumptions": "Hypothèses",
    "graph.speedOfCharge.kmThirtyFastCharge": "Kilomètres par 30 min de charge rapide",
    "graph.electricRange.subTitle": "L'autonomie électrique de la {car} est de {costDeltaText} de {number} kilomètres",
    "graph.electricRange.electricity": "Électrique",
    "graph.electricRange.gasoline": "Essence",
    "vehicle" : "Véhicule",
    "ev.cardetails.calc.FuelConsumptionCa": "Guide de consommation de carburant au Canada",
    "graph.electricityEmissionsTree.kg": "Émissions économisées par arbre",
    "graph.electricityEmissionsTree.lbs": "Émissions économisées par arbre",
    "ev.cardetails.calc.lPer100Km": "L/100 km",
    "ev.cardetails.calc.description": "Description",
    "ev.cardetails.calc.source": "Source",
    "ev.carDetails.calc.kwPerKm": "Kilowatt-heures par 100 km",
    "ev.cardetails.calc.lpkm": " Litres/kilomètre",
    "ev.showSources.kilometerVehicleKWh": "Kilowatt-heures par 100 kilomètres",
    "pricePanels.firstLeasePayment": "Premier paiement de leasing",
    "incentives.planClunker": "envisagez-vous de rendre un vieux véhicule?",
    "evs.KMroundTripCommute": "Kilomètres par jour",
    "pricePanels.lease": "Comptant",
    
    "ev.showSources.vehicleKWh": "Kilowatt-heures par 100 kilomètres",
    "ev.carDetails.mobile" : "Pour plus de détails, y compris les graphiques et les calculs, veuillez consulter cette page sur un ordinateur de bureau.",
    "vehicle.selectVehicle" : "Choisissez un véhicule",
    "ev.showSources.electricityRateCA": "Certaines juridictions canadiennes offrent actuellement un allégement COVID des tarifs d'électricité. Les tarifs et prix publiés sur ce site Web sont basés sur des sources accessibles au public et adaptés à votre code postal. Veuillez consulter votre fournisseur d'électricité local pour confirmer les prix actuels dans votre région.",
    "ev.cardetails.calc.kgCo2PerYr": " kg de CO2 par an",
    "ev.cardetails.calc.kgCo2PerYrShort": " de CO2 par an",
    "graph.electricityEmissions.kgPMWH": "Émissions d'électricité en CO2 kg / MWh",
    "graph.electricRange.total": "Total",
    "assumption.salesTaxPercent": "TVA %",
    "graph.speedOfCharge.kilometersThirtyFastChargeThreeCars": "charge le plus de kilomètres par 30 minutes de charge rapide",
    "ev.carDetails.kilometerBatterySizeTooltip": "Un kilowattheure (kWh) suffit pour parcourir 1 à 6 kilomètres, selon le véhicule. Une batterie d'ordinateur portable typique peut contenir moins de 0,1 kWh.",
    "ev.carDetails.kilometerUsedBatterySizeTooltip": "Un kilowattheure (kWh) suffit pour parcourir 1 à 6 kilomètres, selon le véhicule. Une batterie d'ordinateur portable typique peut contenir moins de 0,1 kWh.",
    "matchScore.yourScore" : "Votre score",
    "matchScore.range": "Autonomie",
    "matchScore.title": "Score De Compatibilité",
    "matchScore.totalScore": "Score Total",
    "matchScore.seats": "Sièges",
    "matchScore.charging": "Recharge",
    "matchScore.budget": "Ajustement",
    "matchScore.adjustment": "Budget",
    "matchScore.maxScore": "Score Maximum",
    "matchScore.popover": "Le score is basé sur vos préférences individuelles",
    "Energy Information Administration": "Énergie Information Administration",
    
    "chargingMap.errorPostcode" : "Erreur: {workingZipcode} n'est pas un code postal valide",
    "billCredit" : "Facture crédit",
    "discountedElectricRate" :"Tarif électrique réduit",
    "financing" : "Financement",
    "freeEquipment" : "Équipement gratuit",
    "hovLane" : "Voie réservée aux VOM",
    "insuranceDiscount" : "Rabais d'assurance",
    "parkingExemption" : "Exemption de stationnement",
    "publicCharging" : "Recharge publique",
    "rebate" : "Rabais",
    "taxCredit" : "Crédit d'impôt",
    "taxExemption" : "Exonération fiscale",
    "tollDiscount" : "Réduction de péage",
}

export default FR_CUSTOM;