import React from "react";
import PropTypes from "prop-types";
import "./IncentiveCard.scss";
import { FormatAsDollars } from "../../../utils/Helpers/Format";
import { useIntl } from 'react-intl';

const IncentiveCard = props => {
    const intl = useIntl() 
    let incentive = props && props.incentive ? props.incentive : {};

    let eligibility = incentive.evaluation && incentive.evaluation.eligibility

    if (eligibility === "ineligible") {
        return null
    }

    let incentiveTypes = {
        "Bill Credit": intl.formatMessage({id:"billCredit", defaultMessage:"Bill Credit"}),
        "Discounted Electric Rate": intl.formatMessage({id:"discountedElectricRate", defaultMessage:"Discounted Electric Rate"}),
        "Financing": intl.formatMessage({id:"financing", defaultMessage:"Financing"}),
        "Free Equipment": intl.formatMessage({id:"freeEquipment", defaultMessage:"Free Equipment"}),
        "HOV Lane": intl.formatMessage({id:"hovLane", defaultMessage:"HOV Lane"}),
        "Insurance Discount": intl.formatMessage({id:"insuranceDiscount", defaultMessage:"Insurance Discount"}),
        "Parking Exemption": intl.formatMessage({id:"parkingExemption", defaultMessage:"Parking Exemption"}),
        "Public Charging": intl.formatMessage({id:"publicCharging", defaultMessage:"Public Charging"}),
        "Rebate": intl.formatMessage({id:"rebate", defaultMessage:"Rebate"}),
        "Tax Credit": intl.formatMessage({id:"taxCredit", defaultMessage:"Tax Credit"}),
        "Tax Exemption": intl.formatMessage({id:"taxExemption", defaultMessage:"Tax Exemption"}),
        "Toll Discount": intl.formatMessage({id:"tollDiscount", defaultMessage:"Toll Discount"}),
    }

    let name = incentive.grantor + " " + incentive.name || "";
    let description = incentive.description || "";

    let amountDescription =
        incentive.typical_amount && incentive.typical_amount !== "N/A"
            ? incentive.typical_amount.replace("-$", "-")
            : "";    

    //code here is used for canadian incentives where the amount would spill onto 2 lines
    let newAmountDescription = ""
    const amountDescriptionArray = amountDescription.split("")
    amountDescriptionArray.forEach((char, i) => {
        if (char === " " && !isNaN(Number(amountDescriptionArray[i - 1])) && !isNaN(Number(amountDescriptionArray[i + 1]))) {
            return
        }
        newAmountDescription += char
    })

    let amount =
        incentive.evaluation &&
            incentive.evaluation.amount_in_purchase &&
            parseInt(incentive.evaluation.amount_in_purchase, 10) > 0
            ? parseInt(incentive.evaluation.amount_in_purchase, 10)
            : 0;
    
    let renderCard = incentive ? (
        <a
            className="evc-card IncentiveCard"
            href={incentive.details_url}
            target="_blank"
            rel="noopener noreferrer"
        >
            <div className="incentive-card-header">{incentiveTypes[incentive.type]}</div>
            <div className="IncentiveCardBody">
                <p className="h1 card-title">
                    {amount ? FormatAsDollars(amount, intl.locale) : newAmountDescription}
                </p>
                <p className="h6">{name}</p>
            </div>
            <div className="pl-5 pr-5">
                <hr />
            </div>
            <div className="IncentiveCardBottom">{description}</div>
        </a>
    ) : null;

    return renderCard;
};

export default IncentiveCard;

IncentiveCard.propTypes = {
    incentive: PropTypes.object,
    compact: PropTypes.bool
};
