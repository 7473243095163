const EN_CUSTOM = {

    "vehicle.zipcode": "Zip code",
    "vehicle.allElectric": "All-electric",
    "detailedCalculations": "Details",
    "vehicle.rides": "Uber rides & eats",
    "vehicle.eats": "Uber Eats only",
    "vehicle.class": "Vehicle class",
    "vehicle.estimatedIncentives": "Incentive total",
    "vehicle.make": "Car make",
    "vehicle.model": "Car model",
    "vehicle.batterySize": "Estimated Battery size",
    "vehicle.electricRange": "Estimated electric range",
    "header.newVehicles": "Discover new vehicles",
    "header.usedVehicles": "Discover used vehicles",
    "footer.generalDisclaimer": "Vehicles displayed may not reflect actual availability. Uber does not endorse or recommend any specific vehicle or car manufacturer. Uber is not responsible for the products or services offered by third - parties, including this cost - savings calculator, and makes no claims related to the effectiveness of such or to potential savings.",
    "homepage.title": "Your EV cost calculator guide",
    "homepage.subTitle": "Jump straight to popular EVs available today",
    "homepage.compare": "Compare the cost of your selection to a similar gas vehicle. An EV can save you money because it can be cheaper to own and maintain than a 100% gasoline-powered car.",
    "homepage.linkCard.browseElectricVehicles": "Browse new {lineBreak} electric vehicles",
    "homepage.linkCard.browseUsedVehicles": "Browse used {lineBreak} electric vehicles",
    "homepage.linkCard.compareVehicles": "Compare new vehicles",
    "homepage.linkCard.incentives": "See available incentives",
    "evs.welcomeSub": "Tell us a little bit about your driving style and preferences so we {lineBreak} can show you the EVs that might best fit your lifestyle.",
    "evs.roundTripCommute": "Average miles driven per day",
    "evs.KMroundTripCommute" : "Average kilometers driven per day",
    "evs.homeChargingAvailability": "Home charging available?",
    "evCard.totalRange": "Estimated Total Range",
    "evCard.totalRange.mobile": "Est. Total Range",
    "used.evCard.electricRangeAvg.short": "Electric range avg.",
    "evSort.title": "Filter by",
    "ev.jumbotron.viewAllCars": "View all cars",
    "assumpion.Pricegasoline": "Gasoline price ($/gal)",
    "ev.incentives.title.default": "Available Incentives and Rebates",
    "incentives.updating": "Updating...",
    "results.updated": "Results have been updated as of ",
    "usedElectricVehicles": "Used vehicles",
    "footer.privacy": "Privacy",
    "footer.accessibility": "Accessibility",
    "footer.terms": "Terms",
    "compareVehicles.subTitle": "See all vehicles",
    "compareVehicles.pickTwo": "Pick at least 2 vehicles to begin the comparison.",
    "incentives.subTitle": "You may be eligible for a range of incentives, including rebates, tax credits, and various other perks. {lineBreak} Select Personalize incentives to learn more about which are available to you.",
    "incentives.title": "Electric vehicle incentives",
    "vehicle.dailyMiles": "miles per day",
    
    
    "feedback.title" : "Feedback",
    "feedback.goal" : "What is your goal of visiting?",
    "feedback.interest": "Are you interested in purchasing an Electric Vehicle?",
    "feedback.batteryElectric": "Battery Electric",
    "feedback.pluginHybrid": "Plug-In Hybrid",
    "feedback.notInterested": "Not Interested",
    "feedback.improve": "How would you improve this tool?",
    "feedback.help": "What can Uber do to help you get an Electric Vehicle?",
    "feedback.submit": "Submit",

    "ev.publicCharging.low" : "Low",
    "ev.publicCharging.medium" : "Medium",
    "ev.publicCharging.high" : "High",
    "ev.publicCharging.free" : "Free",
    "vehicles.new" : "New",
    "vehicles.used" : "Used",

    "header.compareVehicles": "Compare new vehicles",

    "electricVehicles": "New vehicles",
    "compareVehicles": "Compare vehicles",
    "personalizeIncentives": "Personalize incentives",
    "vehicle.afterIncentives": "After incentives",
    "vehicle.type": "Type",
    "vehicle.fuelType": "Fuel type",
    "vehicle.timeToChargeLvlTwo": "Time to Charge - Level 2",
    "vehicle.milesPerThirtyFastCharge": "Miles per 30 min of Fast Charging",
    "vehicle.coTwoEmissions": "CO2 Emission Reduction",
    "graph.graphValues": "Graph Values",
    "graph.costOfOwnership.title": "Cost of ownership",
    "graph.costOfOwnership.buttonMonthly" : "Monthly",
    "graph.costOfOwnership.buttonAnually" : "Anually",
    "graph.costOfOwnership.buttonOverall" : "Overall",
    "graph.monthlyCostToFill.title": "Cost to fill up monthly",
    "evs.matchScoreOptions": "Refine match score",
    "evs.minSeats": "Seats",
    "evCard.electricRange": "Electric range",
    "evCard.matchScore": "Match score",
    "evCard.seeDetails": "See details",
    "used.evCard.yearRange": "Year range",
    "used.evCard.electricRangeAvg": "Electric range average",
    "evSort.matchScore": "Match score",
    "evSort.electricRange": "Electric range",
    "evSort.priceLtH": "Price: low to high",
    "evSort.priceHtL": "Price: high to low",
    "pricePanels.firstLeasePayment": "First Lease Payment",
    "pricePanels.seeAllIncentives": "See all incentives",
    "pricePanels.seeAllCosts": "See all costs",
    "assumption.salesTax": "Sales tax",
    "assumption.electricityRate": "Electricity rate",
    "ev.compareOtherVehicles": "Compare Other Vehicles",
    "incentives.location": "Location",
    "ev.cardetails.calc.arborDayFoundation": "Arbor Day Foundation",
    "incentives.householdSize": "Household size",
    "incentives.taxFilingStatus": "Tax filing status",
    "incentives.incentiveEligibility": "Update incentive eligibility",
    "trees": " trees",
    "vehicle": "Vehicle",
    "vehicle.selectVehicle": "Please Select A Vehicle",
    "ev.cardetails.calc.kgCo2PerYr": " kg of CO2 per year",
    "ev.cardetails.calc.kgCo2PerYrShort" : " of C02 per year",
    "kilometersShort" : "km",
    "vehicle.kilometersShort" : "km",
    "ev.cardetails.calc.lbsCo2PerYrShort": " of CO2 per year",

    "matchScore.title": "Match Score",
    "matchScore.totalScore": "Total Score",
    "matchScore.seats": "Seats",
    "matchScore.range": "Range",
    "matchScore.charging": "Charging",
    "matchScore.budget": "Budget",
    "matchScore.adjustment": "Adjustment",
    "matchScore.maxScore": "Max Score",
    "matchScore.yourScore": "Your Score",
    "matchScore.popover": "Your Match Score is based on your selected preferences.",

    // "ev.electricityRateCalc": "Some Canadian jurisdictions are currently offering COVID relief to lower electricity rates. The rates and pricing published on this website are based on publicly available sources and tailored to your postal code. Please consult your local electricity provider to confirm current pricing in your area.",
    "ev.showSources.electricityRateCA":"Some Canadian jurisdictions are currently offering COVID relief to lower electricity rates. The rates and pricing published on this website are based on publicly available sources and tailored to your postal code. Please consult your local electricity provider to confirm current pricing in your area.", 
    "ev.showSources.electricityRate": "Electricity rate pricing is based off of {website} data.",

    "postcode" : "Postcode", 
    "chargingMap.errorPostcode" : "Error: {workingZipcode} is not a valid Postcode",

    "billCredit" : "Bill credit",
    "discountedElectricRate" :"Discounted electric rate",
    "financing" : "Financing",
    "freeEquipment" : "Free equipment",
    "hovLane" : "HOV Lane",
    "insuranceDiscount" : "Insurance discount",
    "parkingExemption" : "Parking exemption",
    "publicCharging" : "Public charging",
    "rebate" : "Rebate",
    "taxCredit" : "Tax credit",
    "taxExemption" : "Tax exemption",
    "tollDiscount" : "Toll discount",
}

export default EN_CUSTOM;
