import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";

import { FormatAsThousands, FormatAsInt } from "../../../../utils/Helpers/Format";

import Range from '../../../../components/shared/InputElements/Range'
import { useIntl } from 'react-intl';

const SlideMilesDrivenAnnually = ({
  id = "miles-driven-annually-range",
  ...rest
}) => {
  const intl = useIntl()
  const userPrefs = useContext(UserPrefsContext);
  const label = userPrefs.get("country") === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "ev.kilometersDrivenAnnually", defaultMessage: "Kilometers Driven Per Year" }) : intl.formatMessage({ id: "ev.milesDrivenAnnually", defaultMessage: "Miles Driven Per Year" })

   const milesDailyDescription = val => {
     val = val/365
    return `( ~ ${FormatAsInt(val)} ${intl.formatMessage({ id: "vehicle.dailyMiles", defaultMessage: "miles per day"})})  `;
  };
//  let val = val/365
  const description = val => {
    return `${FormatAsThousands(val)} ${userPrefs.get("country") === "Canada" || process.env.REACT_APP_METRIC_SYSTEM ? intl.formatMessage({ id: "vehicle.kilometers", defaultMessage: "kilometers" }) : intl.formatMessage({ id: "vehicle.miles", defaultMessage: "miles" })} ${milesDailyDescription(val)}`;
  };


  return (
    <Range
      id={id}
      value={userPrefs.get("milesDrivenAnnually")}
      label={label}
      rangeMin={1000}
      rangeMax={100000}
      rangeStep={1000}
      description={description}
      handler={e => userPrefs.set({ milesDrivenAnnually: e.target.value })}
      {...rest}
    />
  );
};

export default SlideMilesDrivenAnnually;

SlideMilesDrivenAnnually.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
