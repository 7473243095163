const filterEVs = (evs, filterParams) => {
    const {
      vehicleFormFactorFilters,
      vehicleFuelTypeFilters,
      vehicleMakeFilter,
      vehicleAgeFilter, 
      vehicleClassTypeFilter,
    } = filterParams || {};
    const allowedFuelTypes = Object.keys(vehicleFuelTypeFilters).filter(
      (key) => vehicleFuelTypeFilters[key]
    );

    const allowedFormFactors = Object.keys(vehicleFormFactorFilters).filter(
      (key) => vehicleFormFactorFilters[key]
    );
    const allowedClassTypes = Object.keys(vehicleClassTypeFilter).filter(
      (key) => vehicleClassTypeFilter[key]
    );
    return evs
      .filter(
        ({ make }) => vehicleMakeFilter === "All" || vehicleMakeFilter === make
      )
      .filter(
        ({ fuel }) => 
          allowedFuelTypes.length === 0 ||
          allowedFuelTypes.includes(fuel.toLowerCase())
      )
      .filter(
        ({ seats_min, doors }) => {
            if (allowedClassTypes.includes("ridesEats")){
                return doors > 3 && seats_min > 4
            }
            return true
        }
      )
      .filter(
        ({ form_factor }) =>
          allowedFormFactors.length === 0 ||
          allowedFormFactors.includes(form_factor.toLowerCase())
      )
      .filter(
        ({model_year}) => {
          if (vehicleAgeFilter === "0-1") {
            return (new Date().getFullYear() - model_year < 2)
          }
          if (vehicleAgeFilter === "2-3") {
            return (1 < new Date().getFullYear() - model_year && new Date().getFullYear() - model_year < 4)
          }
          if (vehicleAgeFilter === "4+") {
            return (new Date().getFullYear() - model_year > 3)
          }
          return true
        }
      );
  };
  
  export default filterEVs;
  