import React, { useState } from "react";
import PropTypes from "prop-types";
import HomepageEVCard from "../HomepageEVCard/HomepageEVCard"
import leftBlack from "../../assets/images/icons/leftBlack.svg"
import leftGrey from "../../assets/images/icons/leftGrey.svg"
import rightGrey from "../../assets/images/icons/rightGrey.svg"
import rightBlack from "../../assets/images/icons/rightBlack.svg"

const VehicleCarousel = ({ electricVehicles }) => {

  const onlyBEVs = electricVehicles.filter(ev => ev.handle.includes("BEV"))

  const favoriteCodes = {"Tesla Model 3" : 356}

  const orderedByFavorites = (vehicles) => {
    let favorites = vehicles.filter(ev => ev.electric_vehicle_id === favoriteCodes["Tesla Model 3"])
    const nonPremiumVehicles = vehicles.filter(ev => ev.make === "Hyundai" || ev.make === "Kia" || ev.make === "Nissan")
    favorites = favorites.concat(nonPremiumVehicles)  
    const nonFavorites = vehicles.filter(ev => ev.display_name !== "LEAF PLUS" && ev.electric_vehicle_id !== favoriteCodes["Tesla Model 3"] && ev.make !== "Hyundai" && ev.make !== "Kia" && ev.make !== "Nissan" )

    const ordered = [
      ...favorites,
      ...nonFavorites
    ]
    return ordered;
  }
  
  const sortedEVs = orderedByFavorites(onlyBEVs)
  
  const [vehicleRow, setvehicleRow] = useState(1);

  if (!sortedEVs || sortedEVs.length <= 3) return null;

  const numVehiclesToDisplay = {
    mobile : 2, 
    desktop: 3
  }
  const maxIndex = sortedEVs.length


  const getIndicies = numVehicles => {
    const firstVehicleIndex = (vehicleRow - 1) * numVehicles
    const evIndices = [];

    for (let i = 0; i < numVehicles; i++) {
      evIndices.push(firstVehicleIndex + i <= maxIndex ? firstVehicleIndex + i : null)
    }
    return evIndices
  }

  const maxVehicleRows = (numVehicles) => Math.ceil(maxIndex/numVehicles)

  const selectableEvs = (evs) => {
      return evs.map((ev, i) => {
          return (
            sortedEVs[ev] &&
            <HomepageEVCard
              ev={sortedEVs[ev]}
              key={i}
            />
          );
      });
  }

  const renderDesktop = (
    <>
      <div className="render-cards-container full-width d-flex">
        {selectableEvs(getIndicies(numVehiclesToDisplay.desktop))}
      </div>
      <div className="row vc-toggle-buttons">
          <span className="row-counter">
            {`${vehicleRow} / ${maxVehicleRows(numVehiclesToDisplay.desktop)}`}
          </span>
          <img src={getIndicies(numVehiclesToDisplay.desktop).includes(0) ? leftGrey: leftBlack} alt="Back" onClick={() => setvehicleRow(vehicleRow === 1 ? 1 : vehicleRow - 1)}/>
          <img src={getIndicies(numVehiclesToDisplay.desktop).includes(sortedEVs.length -1) ? rightGrey: rightBlack} alt="Next" onClick={() => setvehicleRow(vehicleRow === maxVehicleRows(numVehiclesToDisplay.desktop) ? maxVehicleRows(numVehiclesToDisplay.desktop) : vehicleRow + 1)}/>
      </div>
    </>
  )

  const renderMobile = (
    <>
    <div className="render-cards-container full-width d-flex">
      {selectableEvs(getIndicies(numVehiclesToDisplay.mobile))}
    </div>
    <div className="row vc-toggle-buttons">
        <span className="row-counter">
          {`${vehicleRow} / ${maxVehicleRows(numVehiclesToDisplay.mobile)}`}
        </span>
        <img src={getIndicies(numVehiclesToDisplay.desktop).includes(0) ? leftGrey: leftBlack} alt="Back" onClick={() => setvehicleRow(vehicleRow === 1 ? 1 : vehicleRow - 1)}/>
        <img src={getIndicies(numVehiclesToDisplay.mobile).includes(sortedEVs.length -1) ? rightGrey: rightBlack} alt="Next" onClick={() => setvehicleRow(vehicleRow === maxVehicleRows(numVehiclesToDisplay.mobile) ? maxVehicleRows(numVehiclesToDisplay.mobile) : vehicleRow + 1)}/>
    </div>
  </>
  )

  return (
    <>
      <div className="full-width d-none d-lg-flex flex-column justify-content-end">
        {renderDesktop}
      </div>

      <div className="full-width d-flex d-lg-none flex-column justify-content-end">
        {renderMobile}
      </div>
    </>
  );
};

export default VehicleCarousel;

VehicleCarousel.propTypes = {
  electricVehicles: PropTypes.array
};
