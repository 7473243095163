import React, { useContext } from "react";
import PropTypes from "prop-types";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import Range from "../../shared/InputElements/Range";
import { useIntl } from 'react-intl';

const descriptionFn = val => {
  return val + " %";
};

const SlideSalesTax = ({
  id = "sales-tax-range",
  description = descriptionFn,
  ...rest
}) => {
  const intl = useIntl()
  const label = intl.formatMessage ? intl.formatMessage({ id: "assumption.salesTax", defaultMessage: "Sales Tax"}) : "Sales Tax"  
  const userPrefs = useContext(UserPrefsContext);

  return (
    <Range
      id={id}
      value={(userPrefs.get("salesTax") * 100).toFixed(2)}
      label={label}
      rangeMin={0}
      rangeMax={10}
      rangeStep={0.01}
      description={description}
      handler={e => userPrefs.set({ salesTax: e.target.value / 100 })}
      {...rest}
    />
  );
};

export default SlideSalesTax;

SlideSalesTax.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  description: PropTypes.func
};
