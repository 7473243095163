import React, { useEffect } from "react";
import PropTypes from "prop-types";
import IncentiveCatalog from "./../../components/IncentiveCatalog/IncentiveCatalog";
import IncentivePreferences from "../../client_customizations/components/IncentivesPreferences/IncentivesPreferences";
import LoadingSpinner from "./../../components/LoadingSpinner/LoadingSpinner";
import { FormattedMessage, useIntl } from 'react-intl';

const Incentives = ({ electricVehicles, incentives, ip, uuid }) => {

  useEffect(() => {
    document.title = process.env.REACT_APP_PAGES_INCENTIVES_TITLE;
  });

  const intl = useIntl()

  const renderIncentives = incentives ? (
    <IncentiveCatalog incentives={incentives} category="all" />
  ) : (
    <LoadingSpinner />
  );

  return (
    <>
      <section className="container">
        <div className="row">
          <div className="col-sm-12">
            <h1 className="text-center page-title h2">
              <FormattedMessage 
                id="incentives.title"
                defaultMessage="Electric Vehicle Incentives"
                description="Electric Vehicle Incentives"
              />
            </h1>
            <p className="lead text-center">
              <FormattedMessage 
                id="incentives.subTitle"
                defaultMessage="You may be eligible for a range of incentives, including rebates, tax credits, and various other benefits. Incentives are personalized for where you live."
                description="Incentives Subtitle"
                values={{
                  lineBreak: <br />
                }}
              />
            </p>
            <IncentivePreferences
              incentives={incentives}
              electricVehicles={electricVehicles}
              titleText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
              btnText={intl.formatMessage ? intl.formatMessage({ id: "personalizeIncentives", defaultMessage: "Personalize Incentives"}) : "Personalize Incentives"}
            />
          </div>
        </div>
        <br />
        {renderIncentives}
        <br />
      </section>
    </>
  );
};

export default Incentives;

Incentives.propTypes = {
  incentives: PropTypes.array,
  electricVehicles: PropTypes.array,
  zip: PropTypes.string,
  ip: PropTypes.string,
  uuid: PropTypes.string
};
