import React, { useState, useContext } from "react";
import UserPrefsContext from "../../../../context/UserPrefs/UserPrefsContext";
import PropTypes from "prop-types";
import { Link, NavLink } from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from "reactstrap";

import zappy_logo from "../../../assets/images/zappy_logo.svg"
import powered_by from "../../../assets/images/powered_by.svg"
import uber from "../../../assets/images/uber_logo_black.svg"
import line from "../../../assets/images/Line 28.svg"
import "./Header.scss";
import {FormattedMessage} from 'react-intl';
import { MAP_TABS } from '../../../../constants/mapTabs';

const Header = ({ page, props }) => {
  const [collapse, setCollapse] = useState(false);
  const userPrefs = useContext(UserPrefsContext);
  const isHomepage =
    window.location.pathname === "/" || window.location.pathname === null;

  return (
    <div className="Header">
      <div className="container" style={{ padding: 0 }}>
        <Navbar expand="lg">
          <div className="header-box">
            <a
              href="https://www.uber.com/drive/services/electric/"
              target="_blank"
              rel="noopener noreferrer"
              className="navbar-brand"
            >
              <div className="zappyride-col-1 logo-margin">
                <img
                  src={uber}
                  alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                  className="img-fluid "
                />
              </div>  
            </a>
              <div className="zappyride-col-1">
                <div className="logo-line">
                  <img
                    src={line}
                    alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                    className="img-fluid"
                  />
                </div>
                <div className="zappyride-col-2">
                  <img
                    src={powered_by}
                    alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                    className="img-fluid top-col-2"
                  />
                  <img
                    src={zappy_logo}
                    alt="{process.env.REACT_APP_FULL_COMPANY_NAME}"
                    className="img-fluid bot-col-2"
                  />
                </div>
              </div>
          </div>
          <NavbarToggler onClick={() => setCollapse(!collapse)} aria-label={collapse ? 'Close navigation' : 'Open navigation'} />
          <Collapse isOpen={collapse} navbar>
            <Nav className="ml-auto" navbar>
              { process.env.REACT_APP_PAGES_HOME_ENABLED  ? (
                <NavItem>
                  <Link to="/" className={isHomepage ? "active" : ""}>
                    <span>
                      <FormattedMessage
                        id="home"
                        defaultMessage="Home"
                        description="Home Header"
                      />
                    </span>
                  </Link>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_VEHICLES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to={`${userPrefs.get("additionalParams")}/vehicles`}
                  >
                    <span>
                      <FormattedMessage
                        id="electricVehicles"
                        defaultMessage="Electric Vehicles"
                        description="Electric Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "vehicles" ? "Active Page" : null}
                    to={`${userPrefs.get("additionalParams")}/used-vehicles`}
                  >
                    <span>
                      <FormattedMessage
                        id="usedElectricVehicles"
                        defaultMessage="Used Vehicles"
                        description="Used Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ) : (null)}
              { process.env.REACT_APP_PAGES_COMPARE_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "compare" ? "Active Page" : null}
                    to={`${userPrefs.get("additionalParams")}/compare-vehicles`}
                  >
                    <span>
                      <FormattedMessage
                        id="compareVehicles"
                        defaultMessage="Compare Vehicles"
                        description="Compare Vehicles Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "home-chargers" ? "Active Page" : null}
                    to="/home-chargers"
                  >
                    <span>
                      <FormattedMessage
                        id="homeChargers"
                        defaultMessage="Home Chargers"
                        description="Home Chargers Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ): (null)}
              { process.env.REACT_APP_PAGES_INCENTIVES_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === "incentives" ? "Active Page" : null}
                    to={`${userPrefs.get("additionalParams")}/incentives`}
                  >
                    <span>
                      <FormattedMessage
                        id="incentives"
                        defaultMessage="Incentives"
                        description="Incentives Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle
                    nav
                    caret
                    className={page === "map" ? "active" : ""}
                  >
                    <span>Maps</span>
                  </DropdownToggle>
                  <DropdownMenu right>
                    {MAP_TABS.filter(tab => tab.isEnabled).map(tab => {
                      return (
                        <DropdownItem
                          key={`nav-item-${tab.id}`}
                          title={page === "map" ? "Active Page" : null}
                          tag={NavLink}
                          to={tab.url}
                        >
                          {tab.name}
                        </DropdownItem>
                      );
                    })}
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) :(null)}
              { process.env.REACT_APP_PAGES_CUSTOM_1_ENABLED  ? (
                <NavItem>
                  <NavLink
                    title={page === process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK ? "Active Page" : null}
                    to={process.env.REACT_APP_PAGES_CUSTOM_1_NAVLINK}
                  >
                    <span>{process.env.REACT_APP_PAGES_CUSTOM_1_NAVNAME}</span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "faq" ? "Active Page" : null}
                    to="/faq"
                  >
                    <span>
                      <FormattedMessage
                        id="FAQ"
                        defaultMessage="FAQ"
                        description="FAQ Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
              { process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (
                <NavItem>
                  <NavLink
                    title={page === "events" ? "Active Page" : null}
                    to="/events"
                  >
                    <span>
                      <FormattedMessage
                        id="events"
                        defaultMessage="Events"
                        description="Events Header"
                      />
                    </span>
                  </NavLink>
                </NavItem>
              ):(null)}
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  page: PropTypes.string
};
