import React, { Component } from "react";
import "url-search-params-polyfill";
import { Switch, Route, withRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.css";
import "./../../css/UberFrontend.css";
import "./Root.scss";
import UserPrefsContext from "../../../context/UserPrefs/UserPrefsContext";
import PageLayout from "../../components/PageLayout/PageLayout";

import banner from "../../assets/images/banner.png";

// import homepageScreenshot from "../../assets/images/metaImages/ev-homepage.png";
import compareVehicleScreenshot from "../../assets/images/metaImages/compare-vehicle-screenshot.png";
import evCatalogScreenshot from "../../assets/images/metaImages/ev-catalog-screenshot.png";
import evPageScreenshot from "../../assets/images/metaImages/ev-page-screenshot.png";
import homeChargerScreenshot from "../../assets/images/metaImages/home-charger-screenshot.png";
import incentivesScreenshot from "../../assets/images/metaImages/incentives-screenshot.png";
import mapScreenshot from "../../assets/images/metaImages/map-screenshot.png";
import faqScreenshot from "../../assets/images/metaImages/faq-screenshot.png";
import costVsRangeScreenshot from "../../assets/images/metaImages/cost-vs-range-screenshot.png";
import eventsScreenshot from "../../assets/images/metaImages/faq-screenshot.png";

// Pages
import Home from "../../components/HomePage/Homepage";
import EVs from "../../../client_customizations/pages/EVs/EVs";
import EVsGraph from "../../../pages/EVs/EVsGraph";
import EV from "../../../pages/EV/EV";
import UsedEV from "../../../pages/UsedEV/UsedEV";
import UsedEVs from "../../../client_customizations/pages/UsedEVs/UsedEVs";
import Incentives from "../../../pages/Incentives/Incentives";
import MapPage from "../../../pages/MapPage/MapPage";
import FAQ from "../../../pages/FAQ/FAQ";
import FourOhFour from "../../../pages/FourOhFour/FourOhFour";
import CompareVehicles from "../../../client_customizations/components/CompareVehicles/CompareVehicles";
import HomeChargers from "../../../pages/HomeChargers/HomeChargers";
import Events from "../../../pages/Events/Events";

// Services
import fetchElectricVehicles from "../../../services/fetchElectricVehicles";
import fetchGasolineVehicles from "../../../services/fetchGasolineVehicles";
import fetchIncentives from "../../../services/fetchIncentives";
import fetchVehicleIncentivesWithHandle from "../../../services/fetchVehicleIncentivesWithHandle";
import fetchHomeChargers from "../../../services/fetchHomeChargers";

// Utilities
import Uuid from "../../../utils/Uuid/Uuid";
import {
  loadState,
  persistState,
} from "../../../utils/LocalStorage/LocalStorage";
import loadUserPrefs from "../../../context/UserPrefs/loadUserPrefs";
import getUserPref from "../../../context/UserPrefs/getUserPref";
import USER_PREF_PRESETS from "../../../context/UserPrefs/USER_PREF_PRESETS";
import GaTracker from "../../utils/GaTracker/GaTracker";
import isIE from "../../../utils/isIE";
import { MAP_TABS } from "../../../constants/mapTabs";
import INTERNATIONAL_TABS from "../../constants/internationalTabs";

class Root extends Component {
  constructor(props) {
    super(props);

    const existingState = loadState() || {};
    const savedPrefs =
      existingState && existingState.userPreferences
        ? existingState.userPreferences
        : {};

    if (!savedPrefs.vehicleFormFactorFilters)
      savedPrefs.vehicleFormFactorFilters = {};
    if (!savedPrefs.vehicleFuelTypeFilters)
      savedPrefs.vehicleFuelTypeFilters = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.chargerFormFactorFilters)
      savedPrefs.chargerFormFactorFilters = {};
    if (!savedPrefs.chargerWifiFilter) savedPrefs.chargerWifiFilter = {};
    if (!savedPrefs.chargerTypeFilters) savedPrefs.chargerTypeFilters = {};
    if (!savedPrefs.vehicleClassTypeFilter)
      savedPrefs.vehicleClassTypeFilter = {};

    this.state = {
      uuid: existingState.uuid || Uuid(),
      ipData: existingState.ipData || null,
      electricVehicles: null,
      usedElectricVehicles: null,
      gasolineVehicles: null,
      incentives: null,
      incentivePrefsModalIsOpen: false,
      userLocation: null,
      userLocationNotFound: false,
      userLocationDealersNotFound: false,
      zipcodeUpdating: false,
      homeChargers: null,

      userPreferences: loadUserPrefs(savedPrefs),
    };
    // Last resort to ensure that the user has a UUID
    if (!this.state.uuid) this.state.uuid = Uuid();

    this.loadElectricVehicleData = this.loadElectricVehicleData.bind(this);
    this.loadGasolineVehicleData = this.loadGasolineVehicleData.bind(this);
    this.loadIncentivesData = this.loadIncentivesData.bind(this);
    this.updateUserPreferences = this.updateUserPreferences.bind(this);
    this.getUserZip = this.getUserZip.bind(this);
    this.incentivePrefsModalToggle = this.incentivePrefsModalToggle.bind(this);
    this.loadDealers = this.loadDealers.bind(this);
  }

  componentDidMount() {
    this.loadElectricVehicleData();
    this.loadGasolineVehicleData();
    this.loadZipcodeData();
    this.loadIncentivesData();
    this.loadDealers();
    this.loadHomeChargers();

    GaTracker.initialize();
    const path = this.props.location?.pathname;
    const page = path + this.props.location.search;
    GaTracker.trackPage(page, { userId: this.state.uuid });

    this.getUserZip();

    const updatedPrefs = {};
    if (this.canadianWebsite()) {
      updatedPrefs.country = "Canada";
    } else {
      updatedPrefs.country = "United States";
    }

    const locationAndLocaleComponents = ['ca', 'fr-ca'];
    const locationAndLocalePrefix = path.split(/\//).filter(
      (component) => locationAndLocaleComponents.includes(component)
    ).join('/');
    updatedPrefs.additionalParams = locationAndLocalePrefix ? '/' + locationAndLocalePrefix : '';

    setTimeout(() => {
      this.updateUserPreferences(updatedPrefs);
    }, 1000);
  }

  // TODO: this should be cleaner
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.userPreferences.zipcode !==
        prevState.userPreferences.zipcode ||
      this.state.userPreferences.householdSize !==
        prevState.userPreferences.householdSize ||
      this.state.userPreferences.householdIncome !==
        prevState.userPreferences.householdIncome ||
      this.state.userPreferences.canTurnInClunker !==
        prevState.userPreferences.canTurnInClunker ||
      this.state.userPreferences.taxFilingStatus !==
        prevState.userPreferences.taxFilingStatus
    ) {
      this.loadElectricVehicleData();
      this.loadIncentivesData();
    } else if (
      this.state.userPreferences.vehicleIdForIncentives !==
        prevState.userPreferences.vehicleIdForIncentives ||
      this.state.userPreferences.vehicleHandleForIncentives !==
        prevState.userPreferences.vehicleHandleForIncentives
    ) {
      this.loadIncentivesData();
    }

    if (
      this.state.userPreferences.zipcode !== prevState.userPreferences.zipcode
    ) {
      this.loadZipcodeData();
      this.loadDealers();
    }

    let prevZip = prevState.userLocation ? prevState.userLocation.zip : null;
    if (this.state.userLocation && this.state.userLocation.zip !== prevZip) {
      this.loadDealers();
    }

    if (!this.state.userLocationNotFound && !this.state.zipcodeUpdating) {
      persistState(this.state);
    }

    const currentPage = prevProps.location.pathname + prevProps.location.search;
    const nextPage = this.props.location.pathname + this.props.location.search;

    if (currentPage !== nextPage) {
      GaTracker.trackPage(nextPage, { userId: this.state.uuid });
    }

    if (this.props.language !== prevProps.language) {
      this.loadIncentivesData();
      this.loadElectricVehicleData();
    }

    if (
      this.state.userPreferences.zipcode !==
        prevState.userPreferences.zipcode ||
      this.state.userPreferences.chargerWifiFilter !==
        prevState.userPreferences.chargerWifiFilter
    ) {
      this.loadHomeChargers();
    }
  }

  async loadElectricVehicleData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences).replace(
        " ",
        ""
      ),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
      lang: this.props.location?.pathname.toLowerCase().includes("fr-ca")
        ? "FR"
        : null,
    };

    try {
      const electricVehicles = await fetchElectricVehicles(params);
      if (!electricVehicles) return;
      if (
        params.postcode ===
        getUserPref("zipcode", this.state.userPreferences)
          .replace(" ", "")
          .toUpperCase()
      )
        this.setState({
          electricVehicles: electricVehicles.newElectricVehicles,
          usedElectricVehicles: electricVehicles.usedElectricVehicles,
        });
    } catch (e) {
      // TODO: handle errors here
    }
  }

  async loadGasolineVehicleData() {
    let params = {
      fuel_type: "gas",
      postcode: getUserPref("zipcode", this.state.userPreferences).replace(
        " ",
        ""
      ),
    };

    try {
      const gasolineVehicles = await fetchGasolineVehicles(params);
      if (!gasolineVehicles) return;
      this.setState({ gasolineVehicles });
    } catch (e) {}
  }

  async loadIncentivesData() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences).replace(
        " ",
        ""
      ),
      vehicle_handle: getUserPref(
        "vehicleHandleForIncentives",
        this.state.userPreferences
      ),
      household_size: getUserPref("householdSize", this.state.userPreferences),
      household_income: getUserPref(
        "householdIncome",
        this.state.userPreferences
      ),
      turn_in_clunker: getUserPref(
        "canTurnInClunker",
        this.state.userPreferences
      )
        ? true
        : null,
      tax_filing_type: getUserPref(
        "taxFilingStatus",
        this.state.userPreferences
      ),
      lang: this.props.location?.pathname.toLowerCase().includes("fr-ca")
        ? "FR"
        : null,
    };

    try {
      const incentives =
        params["vehicle_handle"] !== ""
          ? await fetchVehicleIncentivesWithHandle(params)
          : await fetchIncentives(params);
      if (!incentives) return;
      this.setState({ incentives });
    } catch (e) {
      // TODO: handle error
    }
  }

  async loadHomeChargers() {
    let params = {
      postcode: getUserPref("zipcode", this.state.userPreferences).replace(
        " ",
        ""
      ),
    };

    const wifiPrefs = getUserPref(
      "chargerWifiFilter",
      this.state.userPreferences
    );
    if (wifiPrefs.yes) {
      params.wifi = "true";
    } else if (wifiPrefs.no) {
      params.wifi = "false";
    }

    try {
      const homeChargers = await fetchHomeChargers(params);
      if (!homeChargers) return;
      this.setState({ homeChargers });
    } catch (e) {}
  }

  loadDealers() {
    if (!process.env.REACT_APP_PAGES_DEALERS_ENABLED) {
      return;
    }

    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences).replace(
        " ",
        ""
      ),
      distance: isIE() ? 25 : 100,
    };
    let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/dealers`);

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
        },
      })
      .then((response) => {
        if (response.status === 400) {
          this.setState({
            userLocationDealersNotFound: true,
          });
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (data.dealers) {
          this.setState({
            dealerLocations: data.dealers,
            userLocationDealersNotFound: false,
          });
        }
      });
  }

  loadZipcodeData() {
    const params = {
      postcode: getUserPref("zipcode", this.state.userPreferences).replace(
        " ",
        ""
      ),
      distance: 0,
    };

    let url = new URL(`${process.env.REACT_APP_EV_INFO_API_HOST}/location`);

    let searchParams = new URLSearchParams(params);

    url.search = searchParams;

    window
      .fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${process.env.REACT_APP_EV_INFO_TKN}`,
        },
      })
      .then((response) => {
        if (response.status === 400) {
          this.setState({
            userLocationNotFound: true,
            zipcodeUpdating: false,
          });
        }
        return response;
      })
      .then((response) => response.json())
      .then((data) => {
        if (
          data.location &&
          data.location.postcode.toUpperCase() ===
            getUserPref("zipcode", this.state.userPreferences)
              .replace(" ", "")
              .toUpperCase()
        ) {
          this.setState({
            userLocation: data.location,
            userLocationNotFound: false,
            zipcodeUpdating: false,
          });
          let newPrefs = {
            municipality: data?.location?.region,
            salesTax:
              data?.location?.regional_financial_references?.[0]?.sales_tax
                ?.region / 100.0,
            gasolinePriceInCentsPerGal: data?.location?.regional_fuel_cost[0]
              ?.gasoline
              ? data.location.country === "Canada"
                ? data.location.regional_fuel_cost[0].gasoline.toFixed(0)
                : (data.location.regional_fuel_cost[0].gasoline * 100).toFixed(
                    0
                  )
              : this.state.userPreferences.gasolinePriceInCentsPerGal,
          };     
          process.env.REACT_APP_DYNAMIC_ELECTRIC_RATE &&
            (newPrefs.electricityRate = data?.location?.regional_fuel_cost[0]
              ?.electricity
              ? (
                  data.location.regional_fuel_cost[0]?.electricity / 100
                ).toFixed(4)
              : this.state.userPreferences.electricityRate);
          this.updateUserPreferences(newPrefs);
          this.incentivePrefsModalToggle(false);
        }
      });
  }

  getUserZip() {
    let target = "post-code";
    var match = document.cookie.match(
      new RegExp("(^| )" + target + "=([^;]+)")
    );

    if (match) {
      // For devs outside america to be able to test geolocation
      if ((match[2] = 12200)) match[2] = 10001;

      return this.updateUserPreferences({
        zipcode: match[2],
        workingZipcode: match[2],
      });
    }
  }

  updateUserPreferences(newPrefs) {
    let prefs = Object.assign({}, this.state.userPreferences, newPrefs);
    let newState = {
      userPreferences: prefs,
    };

    if (
      newPrefs.zipcode &&
      this.state.userPreferences.zipcode !== newPrefs.zipcode
    )
      newState.zipcodeUpdating = true;
    this.setState(newState);
  }

  incentivePrefsModalToggle(override) {
    if (typeof override === "boolean") {
      this.setState({ incentivePrefsModalIsOpen: override });
    } else {
      this.setState({
        incentivePrefsModalIsOpen: !this.state.incentivePrefsModalIsOpen,
      });
    }
  }

  canadianWebsite() {
    return this.props.location?.pathname.toLowerCase().includes("/ca");
  }

  render() {
    const ip = this.state.ipData ? this.state.ipData.ip : null;
    const uuid = this.state.uuid;
    const language = this.props.language;
    const changeLanguage = this.props.changeLanguage;

    const userPrefs = {
      get: (key) => getUserPref(key, this.state.userPreferences),
      getPreset: (key) => USER_PREF_PRESETS[key],
      set: this.updateUserPreferences,
      zipcodeIsNotFound: this.state.userLocationNotFound,
      zipcodeIsUpdating: this.state.zipcodeUpdating,
      showIncentivePrefsModal: this.state.incentivePrefsModalIsOpen,
      toggleIncentivePrefsModal: this.incentivePrefsModalToggle,
      syncWorkingZipcode: () =>
        this.updateUserPreferences({
          zipcode: getUserPref("workingZipcode", this.state.userPreferences),
        }),
    };

    return (
      <UserPrefsContext.Provider value={userPrefs}>
        <Switch>
          {INTERNATIONAL_TABS[""].map((tab) => {
            return (
              <Route
                exact
                path={tab.route}
                key={tab.key}
                render={(props) => {
                  return (
                    <Home
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      incentives={this.state.incentives}
                      userPreferences={this.state.userPreferences}
                      userLocation={this.state.userLocation}
                      homePageBannerImage={banner}
                      ip={ip}
                      uuid={uuid}
                      language={language}
                      changeLanguage={changeLanguage}
                      defaultLanguage={tab.language}
                    />
                  );
                }}
              />
            );
          })}
          {process.env.REACT_APP_PAGES_VEHICLES_MODEL_ENABLED
            ? INTERNATIONAL_TABS["vehicles/:evId"].map((tab) => {
                return (
                  <Route
                    path={tab.route}
                    key={tab.key}
                    render={(props) => {
                      return (
                        <PageLayout
                          language={language}
                          changeLanguage={changeLanguage}
                          defaultLanguage={tab.language}
                          props={props}
                          page="vehicles"
                          ip={ip}
                          uuid={uuid}
                          description="Compare Electric Vehicles (EVs) by price, range and features."
                          disclaimers=''
                          title={
                            process.env.REACT_APP_PAGES_VEHICLES_MODEL_TITLE
                          }
                          image={evPageScreenshot}
                        >
                          <EV
                            {...props}
                            electricVehicles={this.state.electricVehicles}
                            userLocation={this.state.userLocation}
                            ip={ip}
                            uuid={uuid}
                          />
                        </PageLayout>
                      );
                    }}
                  />
                );
              })
            : null}
          {process.env.REACT_APP_PAGES_FAQ_ENABLED ? (
            <Route
              exact
              path="/faq"
              render={(props) => {
                return (
                  <PageLayout
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    description="Answers to Frequently Asked Questions about electric vehicles (EVs) and their incentives"
                    page="faq"
                    title={process.env.REACT_APP_PAGES_FAQ_TITLE}
                    image={faqScreenshot}
                  >
                    <FAQ ip={ip} uuid={uuid} />;
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {INTERNATIONAL_TABS.vehicles.map((tab) => {
            return (
              <Route
                exact
                path={tab.route}
                key={tab.key}
                render={(props) => {
                  return (
                    <PageLayout
                      language={language}
                      changeLanguage={changeLanguage}
                      defaultLanguage={tab.language}
                      props={props}
                      ip={ip}
                      uuid={uuid}
                      description=""
                      page="vehicles"
                      title={process.env.REACT_APP_PAGES_VEHICLES_TITLE}
                      image={evCatalogScreenshot}
                    >
                      <EVs
                        {...props}
                        electricVehicles={this.state.electricVehicles}
                        ip={ip}
                        uuid={uuid}
                      />
                    </PageLayout>
                  );
                }}
              />
            );
          })}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED
            ? INTERNATIONAL_TABS["used-vehicles"].map((tab) => {
                return (
                  <Route
                    exact
                    path={tab.route}
                    key={tab.key}
                    render={(props) => {
                      return (
                        <PageLayout
                          language={language}
                          changeLanguage={changeLanguage}
                          defaultLanguage={tab.language}
                          props={props}
                          page="vehicles"
                          ip={ip}
                          e
                          uuid={uuid}
                          title={
                            process.env.REACT_APP_PAGES_USED_VEHICLES_TITLE
                          }
                          image={evCatalogScreenshot}
                        >
                          <UsedEVs
                            {...props}
                            usedElectricVehicles={
                              this.state.usedElectricVehicles
                            }
                            ip={ip}
                            uuid={uuid}
                          />
                        </PageLayout>
                      );
                    }}
                  />
                );
              })
            : null}
          {process.env.REACT_APP_PAGES_USED_VEHICLES_ENABLED
            ? INTERNATIONAL_TABS["used-vehicles/:evId"].map((tab) => {
                return (
                  <Route
                    path={tab.route}
                    key={tab.key}
                    render={(props) => {
                      return (
                        <PageLayout
                          language={language}
                          changeLanguage={changeLanguage}
                          defaultLanguage={tab.language}
                          props={props}
                          page="vehicles"
                          ip={ip}
                          uuid={uuid}
                          disclaimers=''
                          title={
                            process.env.REACT_APP_PAGES_VEHICLES_MODEL_TITLE
                          }
                          image={evPageScreenshot}
                        >
                          <UsedEV
                            {...props}
                            electricVehicles={this.state.usedElectricVehicles}
                            userLocation={this.state.userLocation}
                            ip={ip}
                            uuid={uuid}
                          />
                        </PageLayout>
                      );
                    }}
                  />
                );
              })
            : null}
          {process.env.REACT_APP_PAGES_INCENTIVES_ENABLED
            ? INTERNATIONAL_TABS.incentives.map((tab) => {
                return (
                  <Route
                    exact
                    path={tab.route}
                    key={tab.key}
                    render={(props) => (
                      <PageLayout
                        language={language}
                        changeLanguage={changeLanguage}
                        defaultLanguage={tab.language}
                        props={props}
                        ip={ip}
                        uuid={uuid}
                        page="incentives"
                        description=""
                        disclaimers="Listed incentives may not be available at any given time. Listed incentives reflect an illustrative estimation of available incentives. {process.env.REACT_APP_FULL_COMPANY_NAME} does not recommend or endorse any particular automotive or insurance company."
                        title={process.env.REACT_APP_PAGES_INCENTIVES_TITLE}
                        image={incentivesScreenshot}
                      >
                        <Incentives
                          {...props}
                          electricVehicles={this.state.electricVehicles}
                          incentives={this.state.incentives}
                          ip={ip}
                          uuid={uuid}
                        />
                      </PageLayout>
                    )}
                  />
                );
              })
            : null}
          {MAP_TABS.filter((tab) => tab.isEnabled).map((tab) => {
            return (
              <Route
                exact
                key={tab.id}
                path={tab.url}
                render={(props) => {
                  return (
                    <PageLayout
                      language={language}
                      changeLanguage={changeLanguage}
                      props={props}
                      ip={ip}
                      uuid={uuid}
                      page="map"
                      description={tab.description}
                      title={process.env.REACT_APP_PAGES_CHARGING_TITLE}
                      image={mapScreenshot}
                    >
                      <MapPage
                        {...props}
                        tabId={tab.id}
                        userLocation={this.state.userLocation}
                        ip={ip}
                        uuid={uuid}
                        dealerLocations={this.state.dealerLocations}
                        title={tab.title}
                        zipcode={userPrefs.get("zipcode")}
                      />
                    </PageLayout>
                  );
                }}
              />
            );
          })}
          {process.env.REACT_APP_PAGES_COMPARE_ENABLED
            ? INTERNATIONAL_TABS["compare-vehicles"].map((tab) => {
                return (
                  <Route
                    exact
                    path={tab.route}
                    key={tab.key}
                    render={(props) => {
                      return (
                        <PageLayout
                          language={language}
                          changeLanguage={changeLanguage}
                          defaultLanguage={tab.language}
                          props={props}
                          page="compare"
                          ip={ip}
                          uuid={uuid}
                          title={process.env.REACT_APP_PAGES_COMPARE_TITLE}
                          image={compareVehicleScreenshot}
                        >
                          <CompareVehicles
                            props={props}
                            ip={this.state.ipData ? this.state.ipData.ip : null}
                            uuid={this.state.uuid}
                            electricVehicles={this.state.electricVehicles}
                            gasolineVehicles={this.state.gasolineVehicles}
                          />
                        </PageLayout>
                      );
                    }}
                  />
                );
              })
            : null}
          {process.env.REACT_APP_PAGES_COMPARISON_GRAPH_ENABLED ? (
            <Route
              exact
              path="/comparison-graph"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    page="cost vs range"
                    ip={ip}
                    uuid={uuid}
                    title={process.env.REACT_APP_PAGES_COMPARISON_GRAPH_TITLE}
                    image={costVsRangeScreenshot}
                  >
                    <EVsGraph
                      {...props}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                      displayGraph={true}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_HOME_CHARGERS_ENABLED ? (
            <Route
              exact
              path="/home-chargers"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="home chargers"
                    title={process.env.REACT_APP_PAGES_HOME_CHARGERS_TITLE}
                    image={homeChargerScreenshot}
                  >
                    <HomeChargers
                      {...props}
                      homeChargers={this.state.homeChargers}
                      electricVehicles={this.state.electricVehicles}
                      ip={ip}
                      uuid={uuid}
                    />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          {process.env.REACT_APP_PAGES_EVENTS_ENABLED ? (
            <Route
              exact
              path="/events"
              render={(props) => {
                return (
                  <PageLayout
                    language={language}
                    changeLanguage={changeLanguage}
                    props={props}
                    ip={ip}
                    uuid={uuid}
                    page="events"
                    title={process.env.REACT_APP_PAGES_EVENTS_TITLE}
                    image={eventsScreenshot}
                  >
                    <Events {...props} ip={ip} uuid={uuid} />
                  </PageLayout>
                );
              }}
            />
          ) : null}
          <Route component={FourOhFour} />
        </Switch>
      </UserPrefsContext.Provider>
    );
  }
}

export default withRouter(Root);
